import React from 'react'

export function UpsDownIcon() {
    return (
        <>
            <svg xmlns="http://www.w3.org/2000/svg" width="27" height="27" viewBox="0 0 27 27" fill="none">
                <g clipPath="url(#clip0_2_721)">
                    <path d="M11.2559 1.07301C8.32798 1.49459 5.82737 2.73623 3.78298 4.77484C1.67508 6.88853 0.393006 9.53929 0.0522755 12.5308C-0.0516761 13.4202 0.0060748 15.3895 0.162002 16.2442C0.531608 18.2886 1.3228 20.1077 2.54134 21.7536C4.52797 24.4217 7.41551 26.2062 10.7246 26.8068C11.5794 26.9627 13.5487 27.0205 14.438 26.9165C15.9338 26.7491 17.3602 26.3333 18.6885 25.6807C20.0572 25.005 21.0332 24.312 22.1073 23.2609C23.2104 22.1752 23.9842 21.101 24.683 19.6803C25.3414 18.3521 25.7572 16.9256 25.9247 15.4299C26.0286 14.5405 25.9709 12.5712 25.8149 11.7165C25.2143 8.40737 23.4298 5.51983 20.756 3.5332C19.1158 2.31466 17.3025 1.52924 15.2523 1.15386C14.386 0.992159 12.1164 0.945959 11.2559 1.07301ZM10.8344 7.56999C11.048 7.65084 13.8432 10.4344 13.9471 10.6712C14.1608 11.1621 13.9298 11.7685 13.4389 12.011C12.9307 12.2536 12.5958 12.1496 11.9778 11.549L11.5505 11.139L11.5332 12.808L11.5158 14.4828L11.3599 14.7138C10.9268 15.3721 9.96233 15.3317 9.56962 14.6445C9.44257 14.4192 9.4368 14.3615 9.41947 12.7733L9.40215 11.139L8.95746 11.5663C8.46658 12.0457 8.21248 12.167 7.82555 12.1092C7.17874 12.0053 6.75715 11.3065 6.98816 10.7059C7.07478 10.4633 9.78908 7.75479 10.0721 7.62196C10.3319 7.50068 10.5918 7.48336 10.8344 7.56999ZM16.0724 12.9408C16.1821 13.0217 16.3323 13.1891 16.4073 13.3162C16.5344 13.5414 16.5402 13.5992 16.5575 15.1873L16.5748 16.8217L17.0195 16.3943C17.2967 16.1229 17.545 15.9323 17.6894 15.8861C18.5326 15.6089 19.3468 16.5387 18.9426 17.3183C18.7751 17.6302 16.1301 20.2521 15.8645 20.3618C15.5988 20.4715 15.3505 20.4715 15.1079 20.3676C14.8712 20.2636 12.0876 17.4685 12.0067 17.2548C11.7122 16.4578 12.5034 15.6205 13.3061 15.8861C13.4389 15.9265 13.7046 16.1344 13.976 16.3885L14.4265 16.8217L14.4438 15.1527C14.4611 13.5299 14.4669 13.4837 14.594 13.2758C14.669 13.1545 14.8365 12.9928 14.9693 12.9119C15.287 12.7156 15.7605 12.7329 16.0724 12.9408Z" fill="url(#paint0_linear_2_721)" />
                    <path d="M10.8344 7.56999C11.048 7.65084 13.8432 10.4344 13.9471 10.6712C14.1608 11.1621 13.9298 11.7685 13.4389 12.011C12.9307 12.2536 12.5958 12.1496 11.9778 11.549L11.5505 11.139L11.5332 12.808L11.5158 14.4828L11.3599 14.7138C10.9268 15.3721 9.96233 15.3317 9.56962 14.6445C9.44257 14.4192 9.4368 14.3615 9.41947 12.7733L9.40215 11.139L8.95746 11.5663C8.46658 12.0457 8.21248 12.167 7.82555 12.1092C7.17874 12.0053 6.75715 11.3065 6.98816 10.7059C7.07478 10.4633 9.78908 7.75479 10.0721 7.62196C10.3319 7.50068 10.5918 7.48336 10.8344 7.56999Z" fill="white" fillOpacity="0.2" />
                    <path d="M16.0724 12.9408C16.1821 13.0217 16.3323 13.1891 16.4073 13.3162C16.5344 13.5414 16.5402 13.5992 16.5575 15.1873L16.5748 16.8217L17.0195 16.3943C17.2967 16.1229 17.545 15.9323 17.6894 15.8861C18.5326 15.6089 19.3468 16.5387 18.9426 17.3183C18.7751 17.6302 16.1301 20.2521 15.8645 20.3618C15.5988 20.4715 15.3505 20.4715 15.1079 20.3676C14.8712 20.2636 12.0876 17.4685 12.0067 17.2548C11.7122 16.4578 12.5034 15.6205 13.3061 15.8861C13.4389 15.9265 13.7046 16.1344 13.976 16.3885L14.4265 16.8217L14.4438 15.1527C14.4611 13.5299 14.4669 13.4837 14.594 13.2758C14.669 13.1545 14.8365 12.9928 14.9693 12.9119C15.287 12.7156 15.7605 12.7329 16.0724 12.9408Z" fill="url(#paint1_linear_2_721)" />
                    <path d="M16.0724 12.9408C16.1821 13.0217 16.3323 13.1891 16.4073 13.3162C16.5344 13.5414 16.5402 13.5992 16.5575 15.1873L16.5748 16.8217L17.0195 16.3943C17.2967 16.1229 17.545 15.9323 17.6894 15.8861C18.5326 15.6089 19.3468 16.5387 18.9426 17.3183C18.7751 17.6302 16.1301 20.2521 15.8645 20.3618C15.5988 20.4715 15.3505 20.4715 15.1079 20.3676C14.8712 20.2636 12.0876 17.4685 12.0067 17.2548C11.7122 16.4578 12.5034 15.6205 13.3061 15.8861C13.4389 15.9265 13.7046 16.1344 13.976 16.3885L14.4265 16.8217L14.4438 15.1527C14.4611 13.5299 14.4669 13.4837 14.594 13.2758C14.669 13.1545 14.8365 12.9928 14.9693 12.9119C15.287 12.7156 15.7605 12.7329 16.0724 12.9408Z" fill="url(#paint2_linear_2_721)" />
                </g>
                <defs>
                    <linearGradient id="paint0_linear_2_721" x1="13.3478" y1="26.969" x2="13.3478" y2="0.0381704" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#409B74" />
                        <stop offset="1" stopColor="#70CAA3" stopOpacity="0.62" />
                    </linearGradient>
                    <linearGradient id="paint1_linear_2_721" x1="13.3478" y1="26.969" x2="13.3478" y2="0.0381704" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#409B74" />
                        <stop offset="1" stopColor="#70CAA3" stopOpacity="0.62" />
                    </linearGradient>
                    <linearGradient id="paint2_linear_2_721" x1="15.2714" y1="14.4654" x2="15.2714" y2="20.7172" gradientUnits="userSpaceOnUse">
                        <stop stopColor="white" />
                        <stop offset="1" stopColor="white" stopOpacity="0" />
                    </linearGradient>
                    <clipPath id="clip0_2_721">
                        <rect width="26.9308" height="26.9308" fill="white" />
                    </clipPath>
                </defs>
            </svg>
        </>
    )
}
