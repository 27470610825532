import React, { useState, useRef, useEffect } from "react";
import "./FaqDropdown.css";
import DownLogo from "./down.svg";

interface FaqItem {
  question: string;
  answer: string;
}

interface FaqDropdownProps {
  faqItems: FaqItem[];
}

const Faq: React.FC<FaqDropdownProps> = ({ faqItems }) => {
  const [openIndex, setOpenIndex] = useState<number[]>([]); // Open all FAQs initially
  const [answerHeight, setAnswerHeight] = useState<number>(0);
  const answerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (answerRef.current) {
      setAnswerHeight(openIndex.length > 0 ? answerRef.current.scrollHeight + 100 : 0);
    }
  }, [openIndex]);

  const toggleAnswer = (index: number) => {
    setOpenIndex((prevIndex) =>
      prevIndex.includes(index) ? prevIndex.filter((i) => i !== index) : [...prevIndex, index]
    );
  };

  useEffect(() => {
    // Open all FAQs initially
    const initialOpenIndexes = faqItems.map((_, index) => index);
    setOpenIndex(initialOpenIndexes);
  }, [faqItems]);

  return (
    <div className="faq-dropdown">
      {faqItems.map((item, index) => (
        <div key={index} className="faq-item">
          <div
            className={`faq-question ${openIndex.includes(index) ? "open" : ""}`}
            onClick={() => toggleAnswer(index)}
          >
            {item.question}
            <div className="arrow">
              {openIndex.includes(index) ? (
                <img src={DownLogo} alt="Up Arrow" />
              ) : (
                <img src={DownLogo} alt="Down Arrow" />
              )}
            </div>
          </div>
          <div
            style={{
              maxHeight: openIndex.includes(index) ? `${answerHeight}px` : "0",
              overflow: "hidden",
              transition: "max-height 0.5s ease",
            }}
            className="faq-answer"
            ref={answerRef}
          >
            {item.answer}
            <div>
              <p></p>
            </div>
          </div>
          <div className="line"></div>
        </div>
      ))}
    </div>
  );
};

export default Faq;
