import HttpClient from "../../HttpClient";
import UsdtTicker from "../response/UsdtTicker";
import NgnEurRate from "../response/NgnEurRate";
import { api } from "../../api";
import { Fees } from "../response/Fees";

class FeesTickersService {
  static emptyUsdtTicker: UsdtTicker = {
    value: 0,
    buyFee: 0,
    sellFee: 0,
  };

  static emptyNgnEurRate: NgnEurRate = {
    value: 0,
  };

  private static instance: FeesTickersService | null = null;

  private httpClient: HttpClient = new HttpClient();

  private usdtTicker: UsdtTicker = FeesTickersService.emptyUsdtTicker;
  private ngnEurRate: NgnEurRate = FeesTickersService.emptyNgnEurRate;
  private fees: Fees | null = null;
  private minTradeValueInSatoshi: any = {};

  private constructor() {}

  static getInstance(): FeesTickersService {
    if (!FeesTickersService.instance) {
      FeesTickersService.instance = new FeesTickersService();
    }
    return FeesTickersService.instance;
  }

  getUsdtTicker() {
    return this.usdtTicker;
  }

  getNgnEurRate() {
    return this.ngnEurRate;
  }

  getFees() {
    return this.fees;
  }

  getMinTradeValueInSatoshi() {
    return this.minTradeValueInSatoshi;
  }

  private async fetchUsdtTicker() {
    try {
      const response = await this.httpClient.get<UsdtTicker>(api.common.USDT_TICKER);
      this.usdtTicker = response.data;
    } catch (error) {
      // Handle errors
    }
  }

  private async fetchNgnEurRate() {
    try {
      const response = await this.httpClient.get<NgnEurRate>(api.common.NGN_EUR_RATE);
      this.ngnEurRate = response.data;
    } catch (error) {
      // Handle errors
    }
  }

  private async fetchFees() {
    try {
      const response = await this.httpClient.get<Fees>(api.common.FEES);
      this.fees = response.data;
    } catch (error) {
      // Handle errors
    }
  }

  private async fetchMinTradeValueInSatoshi() {
    try {
      const response = await this.httpClient.get<any>(api.common.MIN_TRADE_VALUE_IN_SATOSHI);
      this.minTradeValueInSatoshi = response.data;
    } catch (error) {
      // Handle errors
    }
  }

  startFetchingInterval(intervalInMilliseconds: number = 15 * 60 * 1000) {
    this.fetchUsdtTicker().then(() => {});
    this.fetchNgnEurRate().then(() => {});

    setInterval(() => {
      this.fetchUsdtTicker().then(() => {});
      this.fetchNgnEurRate().then(() => {});
    }, intervalInMilliseconds);
  }

  fetchFeesAndMinTradeValueInSatoshi() {
    this.fetchFees().then(() => {});
    this.fetchMinTradeValueInSatoshi().then(() => {});
  }
}

export default FeesTickersService;
