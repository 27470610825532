import React from 'react';

import '../dashboard/Dashboard.css'

import academyImg from './academy.png'
import tutorsImg from './tutors.png'

const Academy = () => {
    return (
        <div>
            <div className="dashboard-container" style={{marginTop: '10px', marginBottom: '50px'}}>
                <div style={{display: 'flex', justifyContent: 'center', marginBottom: '40px'}}>
                    <img src={academyImg} style={{maxWidth: '400px'}} alt="academy"></img>
                </div>

                <div style={{marginLeft: '6px'}}>
                    <h3 style={{textAlign: 'center'}}>vTrader Academy to Reward Crypto Enthusiasts with up to $500
                        Monthly for Teaching Crypto</h3>
                    <p style={{textAlign: 'justify'}}>The largest barrier to entry for cryptocurrency in emerging
                        countries like Nigeria, and rest-of-Africa, is education and vTrader Africa says it’s about to
                        change that. VTrader teaches beginner-friendly cryptocurrency lessons through blog posts, social
                        media, AMA sessions, and the telegram crypto community and is taking the next step to encourage
                        communal learning by empowering enthusiasts interested in becoming our tutor afﬁliates.</p>
                    <p style={{textAlign: 'justify'}}>We have provided a $30,000 pool to incentivize qualiﬁed tutors for
                        delivering sound crypto knowledge. In this program, qualiﬁed tutors will only be required to
                        hold two virtual classes in a month and can earn up to $250 per class, or $500 in a month.
                        vTrader is a P2P Crypto wallet app that was created to ease the process of buying and selling
                        bitcoin and other crypto.</p>

                    <h3 style={{textAlign: 'left', marginTop: '35px'}}>Become a Tutor Today - <a
                        href="https://docs.google.com/forms/d/e/1FAIpQLSdXqVJlYMhaBCQ8kz42FlPAkzDywpHVs017_r6szYDNq0EZNw/viewform"
                        target="_blank">Take the Application Test</a></h3>
                    <p style={{textAlign: 'justify'}}>Are you a natural educator who has signiﬁcant knowledge of
                        blockchain and cryptocurrency? Do you enjoy sharing this knowledge? With #MyvTraderClass, we are
                        calling all cryptocurrency enthusiasts and community leaders to become a tutor, organize your
                        event, and earn up to $250 per meetup. This pilot #MyVTraderClass campaign launches and closes
                        within a 60-day period. To get started, all interested tutors should complete this Application
                        Test. Below, we show the details regarding our evaluation of events, as well as the
                        corresponding bonuses that we can give depending on the scores of these events.</p>
                    <div style={{display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center'}}>
                        <img src={tutorsImg} style={{maxWidth: '400px', marginBottom: '10px'}} alt="tutors"></img>
                        <table style={{borderCollapse: 'collapse', width: '300px'}}>
                            <tr className="history-table-row">
                                <th className="history-table-thead-text">Event Score</th>
                                <th style={{textAlign: 'end'}} className="history-table-thead-text">Bonus</th>
                            </tr>
                            <tr className="history-table-row">
                                <td className="history-table-cell">10 - 29 points</td>
                                <td style={{textAlign: 'end'}} className="history-table-cell">$25</td>
                            </tr>
                            <tr className="history-table-row">
                                <td className="history-table-cell">30 - 49 points</td>
                                <td style={{textAlign: 'end'}} className="history-table-cell">$50</td>
                            </tr>
                            <tr className="history-table-row">
                                <td className="history-table-cell">50 - 69 points</td>
                                <td style={{textAlign: 'end'}} className="history-table-cell">$100</td>
                            </tr>
                            <tr className="history-table-row">
                                <td className="history-table-cell">70 - 79 points</td>
                                <td style={{textAlign: 'end'}} className="history-table-cell">$150</td>
                            </tr>
                            <tr className="history-table-row">
                                <td className="history-table-cell">80 - 89 points</td>
                                <td style={{textAlign: 'end'}} className="history-table-cell">$200</td>
                            </tr>
                            <tr className="history-table-row">
                                <td className="history-table-cell">90 - 100 points</td>
                                <td style={{textAlign: 'end'}} className="history-table-cell">$250</td>
                            </tr>
                        </table>
                    </div>

                    <h3 style={{textAlign: 'left', marginTop: '35px'}}>Terms and Conditions:</h3>
                    <ul>
                        <li style={{marginBottom: '10px'}}>All tutors must have an active vTrader account and completed KYC.</li>
                        <li style={{marginBottom: '10px'}}>All aspiring tutors are required to ace the tutor assessment test with an overall score of at least 85% (17/20) to qualify as tutors.</li>
                        <li style={{marginBottom: '10px'}}>Tutors are required to go through the Tutor manual thoroughly to gain clarity on vTrader’s grading system.</li>
                        <li style={{marginBottom: '10px'}}>Tutors are expected to prepare themselves adequately before their class and are to only use materials provided by vTrader for their training sessions.</li>
                        <li style={{marginBottom: '10px'}}>All Tutors are to be part of the vTrader Africa Community for announcements, updates, and live meet-ups.</li>
                        <li style={{marginBottom: '10px'}}>Tutors will be graded on a weekly basis and rewarded five working days post-class evaluation and conclusion.</li>
                        <li style={{marginBottom: '10px'}}>vTrader reserves the right to the final interpretation of this activity.</li>
                        <li style={{marginBottom: '10px'}}>vTrader reserves the ability to disqualify any participants showing signs of fraudulent behavior immediately.</li>
                        <li style={{marginBottom: '10px'}}>vTrader reserves the right to cancel or amend any Activity or Activity Rules at our sole discretion.</li>
                    </ul>

                    <h3 style={{textAlign: 'left', marginTop: '35px'}}>Start Planning and promoting Your Class!</h3>
                    <p>Tag <a href="https://twitter.com/vtraderafrica" target="_blank">@VTraderafrica</a> on social media handles for reposts!</p>
                </div>

            </div>
        </div>
    );
};

export default Academy;