export const detectStringType = (str: string): boolean => {
  // Convert the string to a number
  const number = parseFloat(str);

  // Check the sign of the number
  if (isNaN(number)) {
    // console.log("Not a valid number");
    return false;
  } else if (number > 0) {
    // console.log("Positive");
    return true;
  } else if (number < 0) {
    // console.log("Negative");
    return false;
  } else {
    // console.log("Zero");
    return true;
  }
};
