import React, { useEffect, useState, useRef } from "react";
import Marquee from "react-fast-marquee";
import aaveIcon from "./icons/coin-icons/icon_aave.png";
import apeIcon from "./icons/coin-icons/icon_ape.png";
import adaIcon from "./icons/coin-icons/icon_ada.png";
import avaxIcon from "./icons/coin-icons/icon_avax.png";
import algoIcon from "./icons/coin-icons/icon_algo.png";
import atomIcon from "./icons/coin-icons/icon_atom.png";
import btcIcon from "./icons/coin-icons/icon_btc.png";
import daiIcon from "./icons/coin-icons/icon_dai.png";
import dotIcon from "./icons/coin-icons/icon_dot.png";
import compIcon from "./icons/coin-icons/icon_comp.png";
import dogeIcon from "./icons/coin-icons/icon_doge.png";
import ethIcon from "./icons/coin-icons/icon_eth.png";
import grtIcon from "./icons/coin-icons/icon_grt.png";
import linkIcon from "./icons/coin-icons/icon_link.png";
import ltcIcon from "./icons/coin-icons/icon_ltc.png";
import maticIcon from "./icons/coin-icons/icon_matic.png";
import mkrIcon from "./icons/coin-icons/icon_mkr.png";
import solIcon from "./icons/coin-icons/icon_sol.png";
import umaIcon from "./icons/coin-icons/icon_uma.png";
import uniIcon from "./icons/coin-icons/icon_uni.png";
import usdcIcon from "./icons/coin-icons/icon_usdc.png";
import xlmIcon from "./icons/coin-icons/icon_xlm.png";
import xrpIcon from "./icons/coin-icons/icon_xrp.png";
import logoIcon from "./icons/lg.png";
import hamburgerIcon from "./icons/hamburger.png";
import "./Header.css";
import MarketData from "../../services/api/common/response/BittrexMarketPrice";
import BittrexMarketSummaryService from "../../services/bittrex-market-summary/BittrexMarketSummaryService";
import { formatNumber } from "../../services/utils/formatNumber";
import RightPanelModal from "../right-panel-modal/RightPanelModal";
import BittrexMarketSummary from "../../services/api/common/response/BittrexMarketSummary";
import { detectStringType } from "../../services/utils/detectStringType";

const Header = () => {
  const [isRightPanelModalOpen, setRightPanelModalOpen] = useState(false);

  const [marketData, setMarketData] = useState<MarketData | null>(null);
  const [detailedMarketSummaries, setDetailedMarketSummaries] =
    useState<Array<BittrexMarketSummary> | null>([]);
  const [marketDataItemClass, setMarketDataItemClass] = useState<{ [key: string]: string } | null>(
    null
  );

  const openLeftPanelModal = () => {
    setRightPanelModalOpen(true);
  };

  useEffect(() => {
    const fetchData = async () => {
      const marketSummaryService = BittrexMarketSummaryService.getInstance();
      const summaries = marketSummaryService.getMarketSummaries();
      const detailedSummaries = marketSummaryService.getMarketDetailedSummaries();
      setMarketData(summaries);
      setDetailedMarketSummaries(detailedSummaries);
    };

    fetchData().then(() => {});

    const intervalId = setInterval(fetchData, 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  useEffect(() => {
    if (marketData && detailedMarketSummaries) {
      console.log(detailedMarketSummaries);
      const marketItemClass = detailedMarketSummaries
        .filter((summary) => !!summary.updated)
        .map((summary) => {
          const market = summary.market.replace("USDT-", "");
          // const marketData = summaries[market as keyof MarketData];
          const marketChange24h = summary.percentChange24h.toString();
          const itemClass = detectStringType(marketChange24h)
            ? "market-data-item-up"
            : "market-data-item-down";
          return { [market]: itemClass };
        });
      const marketDataItemClassObj = Object.assign({}, ...marketItemClass);
      setMarketDataItemClass(marketDataItemClassObj);
    }
    console.log([marketData, detailedMarketSummaries, marketDataItemClass], "marketData");
  }, [marketData, detailedMarketSummaries]);

  // Create a function to generate JSX for each market data item
  const renderMarketDataItem = (icon: string, label: string, marketValue: number) => (
    <span className="market-data-item" key={label}>
      <img src={icon} alt="ic" className="market-icon" />
      <span className="market-data-text">
        {label} <span className={marketDataItemClass?.[label]}>${formatNumber(marketValue)}</span>
      </span>
    </span>
  );

  return (
    <div>
      <header className="header">
        <div className="top-bar-title">
          <span>Current Rates</span>
        </div>
        {marketData && (
          <Marquee pauseOnHover={true} autoFill>
            {renderMarketDataItem(aaveIcon, "AAVE", marketData.AAVE)}
            {renderMarketDataItem(adaIcon, "ADA", marketData.ADA)}
            {renderMarketDataItem(apeIcon, "APE", marketData.APE)}
            {renderMarketDataItem(algoIcon, "ALGO", marketData.ALGO)}
            {renderMarketDataItem(atomIcon, "ATOM", marketData.ATOM)}
            {renderMarketDataItem(avaxIcon, "AVAX", marketData.AVAX)}
            {renderMarketDataItem(btcIcon, "BTC", marketData.BTC)}
            {renderMarketDataItem(compIcon, "COMP", marketData.COMP)}
            {renderMarketDataItem(daiIcon, "DAI", marketData.DAI)}
            {renderMarketDataItem(dogeIcon, "DOGE", marketData.DOGE)}
            {renderMarketDataItem(dotIcon, "DOT", marketData.DOT)}
            {renderMarketDataItem(ethIcon, "ETH", marketData.ETH)}
            {renderMarketDataItem(grtIcon, "GRT", marketData.GRT)}
            {renderMarketDataItem(linkIcon, "LINK", marketData.LINK)}
            {renderMarketDataItem(ltcIcon, "LTC", marketData.LTC)}
            {renderMarketDataItem(maticIcon, "MATIC", marketData.MATIC)}
            {renderMarketDataItem(mkrIcon, "MKR", marketData.MKR)}
            {renderMarketDataItem(solIcon, "SOL", marketData.SOL)}
            {renderMarketDataItem(umaIcon, "UMA", marketData.UMA)}
            {renderMarketDataItem(uniIcon, "UNI", marketData.UNI)}
            {renderMarketDataItem(usdcIcon, "USDC", marketData.USDC)}
            {renderMarketDataItem(xlmIcon, "XLM", marketData.XLM)}
            {renderMarketDataItem(xrpIcon, "XRP", marketData.XRP)}
          </Marquee>
        )}
        <div className="menu-container">
          <span className="menu-hamburger">
            <span className="login-btn">
              <a
                href="https://app.vtrader.io/login"
                target="_blank"
                className="right-panel-big-section-link"
              >
                Login
              </a>
            </span>
            <span>More</span>
            {!isRightPanelModalOpen && (
              <img
                src={hamburgerIcon}
                alt="Menu"
                className="menu-icon"
                onClick={openLeftPanelModal}
              />
            )}
            {isRightPanelModalOpen && (
              <img
                src={hamburgerIcon}
                alt="Menu"
                className="menu-icon"
                style={{ visibility: "hidden" }}
              />
            )}
          </span>
          {isRightPanelModalOpen && (
            <RightPanelModal closeModal={() => setRightPanelModalOpen(false)} />
          )}
        </div>
      </header>
    </div>
  );
};

export default Header;
