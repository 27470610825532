import React from 'react'

export function BidIcon() {
    return (
        <>
            <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0_2_696)">
                    <path d="M12.9843 25.969C20.1555 25.969 25.9688 20.1556 25.9688 12.9845C25.9688 5.81336 20.1555 0 12.9843 0C5.81318 0 -0.000183105 5.81336 -0.000183105 12.9845C-0.000183105 20.1556 5.81318 25.969 12.9843 25.969Z" fill="url(#paint0_linear_2_696)" />
                    <path d="M13.4816 7.07247C13.6037 7.11887 13.7234 7.2312 14.8736 8.39121C15.6648 9.18978 16.1533 9.69773 16.1899 9.76611C16.3218 10.0152 16.2754 10.3498 16.0775 10.5622C15.9286 10.7234 15.7845 10.7869 15.5696 10.7869C15.2521 10.7894 15.2985 10.826 13.8674 9.39247C12.4535 7.97605 12.5047 8.0371 12.5047 7.72695C12.5047 7.51937 12.5658 7.36796 12.7123 7.22144C12.915 7.02118 13.2007 6.96501 13.4816 7.07247Z" fill="white" />
                    <path d="M13.8056 9.48033C14.4455 10.1202 14.9705 10.6574 14.9705 10.6696C14.9705 10.6843 14.868 10.76 14.7458 10.8381C13.8081 11.4462 13.0217 12.213 12.4503 13.0776C12.3379 13.2461 12.2354 13.4072 12.2183 13.4317C12.1938 13.4732 12.0742 13.3608 11.0021 12.2912L9.81279 11.1043L10.1156 10.8943C10.6016 10.5549 10.9288 10.2813 11.3586 9.85153C11.8397 9.37043 12.3282 8.76968 12.5333 8.40824C12.5626 8.35696 12.5968 8.313 12.6114 8.313C12.6261 8.313 13.1634 8.83806 13.8056 9.48033Z" fill="white" />
                    <path d="M9.38636 10.9995C9.44986 11.0117 9.54998 11.0483 9.60615 11.0801C9.66232 11.1142 10.2704 11.7052 10.9566 12.3964C12.3535 13.7981 12.2974 13.7273 12.2974 14.0765C12.2949 14.3623 12.1728 14.5552 11.9139 14.6846C11.7918 14.7457 11.7357 14.7603 11.6014 14.7603C11.2937 14.7603 11.2985 14.7652 9.91142 13.3756C8.5243 11.9861 8.54872 12.0154 8.54872 11.693C8.54872 11.2412 8.93213 10.9238 9.38636 10.9995Z" fill="white" />
                    <path d="M14.8367 12.4159L15.6914 13.2706L15.2396 13.7224L14.7878 14.1742L13.9282 13.3146C13.4544 12.8408 13.0661 12.4427 13.0661 12.4305C13.0661 12.3963 13.9136 11.5611 13.9502 11.5611C13.9673 11.5611 14.3678 11.9445 14.8367 12.4159Z" fill="white" />
                    <path d="M16.2043 13.2705C16.2678 13.3023 16.6464 13.6686 17.2813 14.3133C17.8186 14.8628 18.2801 15.3439 18.3094 15.3829C18.3387 15.4245 18.3851 15.5295 18.4144 15.6149C18.4584 15.7468 18.4657 15.8103 18.4584 15.9935C18.4486 16.1791 18.434 16.2426 18.3656 16.3842C18.1702 16.7945 17.7233 17.0289 17.2764 16.9581C16.937 16.9044 16.937 16.9019 15.7965 15.7639C14.8929 14.8603 14.7537 14.7114 14.7391 14.6332C14.7049 14.4501 14.7293 14.4159 15.269 13.8542C15.8917 13.207 15.9723 13.1557 16.2043 13.2705Z" fill="url(#paint1_linear_2_696)" />
                    <path d="M13.1394 15.7738C13.2859 15.8202 13.4373 15.952 13.5033 16.0912C13.5472 16.1865 13.5546 16.2451 13.5546 16.5357V16.8727H10.7339H7.91329V16.5406C7.91329 16.2353 7.91818 16.2011 7.97679 16.0839C8.05494 15.9276 8.17948 15.8202 8.33822 15.7738C8.51405 15.72 12.9709 15.7225 13.1394 15.7738Z" fill="white" />
                    <path d="M13.6767 17.0217C13.96 17.0974 14.2042 17.305 14.3385 17.5834C14.4118 17.7348 14.4216 17.7787 14.4289 18.0083C14.4411 18.3136 14.4118 18.4064 14.2726 18.5114L14.1822 18.5822L10.7657 18.5895C7.4005 18.5944 7.34922 18.5944 7.25641 18.5456C7.09279 18.4625 7.04884 18.3722 7.03907 18.1109C7.02197 17.6664 7.19536 17.3392 7.57389 17.1096C7.78879 16.9802 7.72286 16.9826 10.7462 16.9851C13.1272 16.9851 13.5619 16.9924 13.6767 17.0217Z" fill="url(#paint2_linear_2_696)" />
                </g>
                <defs>
                    <linearGradient id="paint0_linear_2_696" x1="12.9843" y1="25.969" x2="12.9843" y2="0" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#409B74" />
                        <stop offset="1" stopColor="#70CAA3" stopOpacity="0.62" />
                    </linearGradient>
                    <linearGradient id="paint1_linear_2_696" x1="15.6341" y1="13.2875" x2="17.5578" y2="16.6539" gradientUnits="userSpaceOnUse">
                        <stop stopColor="white" />
                        <stop offset="1" stopColor="white" stopOpacity="0" />
                    </linearGradient>
                    <linearGradient id="paint2_linear_2_696" x1="10.7347" y1="18.5916" x2="10.7347" y2="16.9844" gradientUnits="userSpaceOnUse">
                        <stop stopColor="white" stopOpacity="0" />
                        <stop offset="1" stopColor="white" />
                    </linearGradient>
                    <clipPath id="clip0_2_696">
                        <rect width="25.969" height="25.969" fill="white" />
                    </clipPath>
                </defs>
            </svg>

        </>
    )
}
