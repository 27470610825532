/* eslint-disable react/jsx-no-target-blank */
import React, { useEffect, useState } from "react";
import Modal from "react-modal";

import "./RightPanelModal.css";
import closeIcon from "./icons/close.png";
import { Link } from "react-router-dom";

import facebookIcon from "./icons/facebook.png";
import linkedinIcon from "./icons/linkedin.png";
import twitterIcon from "./icons/twitter.png";
import instagramIcon from "./icons/instagram.png";
import youtubeIcon from "./icons/youtube.png";
import telegramIcon from "./icons/telegram.png";
import mediumIcon from "./icons/medium.png";

const customStyles = {
  content: {
    width: "350px",
    height: "93%",
    left: "auto",
    right: "0",
    bottom: "0",
    padding: "0",
    margin: "0",
    boxShadow: "none",
    border: "none",
  },
  overlay: { zIndex: 1000 },
};

interface LeftPanelModalProps {
  closeModal: () => void;
}

Modal.setAppElement("#root");

const LeftPanelModal: React.FC<LeftPanelModalProps> = ({ closeModal }) => {
  const [scrollDisabled, setScrollDisabled] = useState(false);

  useEffect(() => {
    if (!scrollDisabled) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }

    return () => {
      document.body.style.overflow = "auto";
    };
  }, [scrollDisabled]);

  return (
    <div>
      <Modal isOpen={true} onRequestClose={closeModal} style={customStyles}>
        <div className="right-panel-modal-container">
          <div className="right-panel-title">
            <img
              src={closeIcon}
              alt="Close"
              className="right-panel-exit-icon"
              onClick={() => {
                closeModal();
                setScrollDisabled(true);
              }}
            />
          </div>

          <div className="right-panel-big-section">
            <div
              onClick={() => {
                closeModal();
                setScrollDisabled(true);
              }}
            >
              <Link to="/landing" className="right-panel-big-section-link">
                <p className="right-panel-big-section-text">Home</p>
              </Link>
            </div>
            <div
              onClick={() => {
                closeModal();
                setScrollDisabled(true);
              }}
            >
              <a
                href="https://app.vtrader.io/account"
                target="_blank"
                className="right-panel-big-section-link"
              >
                <p className="right-panel-big-section-text">Account</p>
              </a>
            </div>
            {/* <div onClick={() => {
                            closeModal();
                            setScrollDisabled(true);
                        }}>
                            <a href="https://app.vtrader.io/login" target='_blank' className="right-panel-big-section-link">
                                <p className="right-panel-big-section-text">Login</p>
                            </a>
                        </div> */}
            <div
              onClick={() => {
                closeModal();
                setScrollDisabled(true);
              }}
            >
              <a
                href="https://vtrader.io/news"
                target="_blank"
                className="right-panel-big-section-link"
              >
                <p className="right-panel-big-section-text" style={{ cursor: "pointer" }}>
                  News
                </p>
              </a>
            </div>
            <div
              onClick={() => {
                closeModal();
                setScrollDisabled(true);
              }}
            >
              <a
                href="https://app.vtrader.io/exchanged-assets"
                target="_blank"
                className="right-panel-big-section-link"
              >
                <p className="right-panel-big-section-text">Exchanged Assets</p>
              </a>
            </div>
            <div
              onClick={() => {
                closeModal();
                setScrollDisabled(true);
              }}
            >
              <a
                href="https://app.vtrader.io/fees"
                target="_blank"
                className="right-panel-big-section-link"
              >
                <p className="right-panel-big-section-text">Fees</p>
              </a>
            </div>
            <div
              onClick={() => {
                closeModal();
                setScrollDisabled(true);
              }}
            >
              <a
                href="https://app.vtrader.io/about"
                target="_blank"
                className="right-panel-big-section-link"
              >
                <p className="right-panel-big-section-text">About</p>
              </a>
            </div>
            <div
              onClick={() => {
                closeModal();
                setScrollDisabled(true);
              }}
            >
              <a
                href="https://app.vtrader.io/faq"
                target="_blank"
                className="right-panel-big-section-link"
              >
                <p className="right-panel-big-section-text">Faq</p>
              </a>
            </div>
          </div>

          <div className="right-panel-icon-section">
            <div
              onClick={() => {
                closeModal();
                setScrollDisabled(true);
              }}
            >
              <a
                href="https://app.vtrader.io/terms-and-conditions"
                target="_blank"
                className="right-panel-big-section-link"
              >
                <p className="right-panel-small-section-text">Terms & Conditions</p>
              </a>
            </div>
            <div
              onClick={() => {
                closeModal();
                setScrollDisabled(true);
              }}
            >
              <a
                href="https://app.vtrader.io/privacy-policy"
                target="_blank"
                className="right-panel-big-section-link"
              >
                <p className="right-panel-small-section-text">Privacy Policy</p>
              </a>
            </div>
            <div
              onClick={() => {
                closeModal();
                setScrollDisabled(true);
              }}
            >
              <a
                href="https://app.vtrader.io/cookie-policy"
                target="_blank"
                className="right-panel-big-section-link"
              >
                <p className="right-panel-small-section-text">Cookie Policy</p>
              </a>
            </div>
            <div
              onClick={() => {
                closeModal();
                setScrollDisabled(true);
              }}
            >
              <a
                href="https://app.vtrader.io/aml-policy"
                target="_blank"
                className="right-panel-big-section-link"
              >
                <p className="right-panel-small-section-text">AML & CTF Policy</p>
              </a>
            </div>
            <div
              onClick={() => {
                closeModal();
                setScrollDisabled(true);
              }}
            >
              <a
                href="https://app.vtrader.io/refund-policy"
                target="_blank"
                className="right-panel-big-section-link"
              >
                <p className="right-panel-small-section-text">Refund Policy</p>
              </a>
            </div>
            <p className="right-panel-small-section-text">support@vtrader.io</p>
            <p className="right-panel-small-section-text">©2024 vTrader. All Rights Reserved.</p>
            <a href="https://twitter.com/vTrader_io">
              <img src={twitterIcon} alt="c" className="right-panel-sm-icon" />
            </a>
            <a href="https://twitter.com/vTraderAfrica">
              <img src={twitterIcon} alt="c" className="right-panel-sm-icon" />
            </a>
            <a href="https://www.linkedin.com/company/13012475/admin/feed/posts/">
              <img src={linkedinIcon} alt="c" className="right-panel-sm-icon" />
            </a>
            <a href="https://www.facebook.com/vtrader.io/">
              <img src={facebookIcon} alt="c" className="right-panel-sm-icon" />
            </a>
            <a href="https://www.instagram.com/vtraderafrica/">
              <img src={instagramIcon} alt="c" className="right-panel-sm-icon" />
            </a>
            {/* <a href="https://www.instagram.com/vtrader.io/"><img src={instagramIcon} alt="c"
                            className="right-panel-sm-icon" /></a>
                        <a href="https://www.youtube.com/@vtrader9212"><img src={youtubeIcon} alt="c"
                            className="right-panel-sm-icon" /></a>
                        <a href="https://t.me/vtrader_exchange"><img src={telegramIcon} alt="c"
                            className="right-panel-sm-icon" /></a>
                        <a href="https://medium.com/@marketing_82136/"><img src={mediumIcon} alt="c"
                            className="right-panel-sm-icon" /></a> */}
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default LeftPanelModal;
