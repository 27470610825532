import React, { useEffect, useState } from "react";
import Marquee from "react-fast-marquee";
import ContentLoader, { Instagram } from "react-content-loader";

import "./Dashboard.css";

import aaveIcon from "./coin-icons/icon_aave.png";
import apeIcon from "./coin-icons/icon_ape.png";
import adaIcon from "./coin-icons/icon_ada.png";
import avaxIcon from "./coin-icons/icon_avax.png";
import algoIcon from "./coin-icons/icon_algo.png";
import atomIcon from "./coin-icons/icon_atom.png";
import btcIcon from "./coin-icons/icon_btc.png";
import daiIcon from "./coin-icons/icon_dai.png";
import dotIcon from "./coin-icons/icon_dot.png";
import compIcon from "./coin-icons/icon_comp.png";
import dogeIcon from "./coin-icons/icon_doge.png";
import ethIcon from "./coin-icons/icon_eth.png";
import grtIcon from "./coin-icons/icon_grt.png";
import linkIcon from "./coin-icons/icon_link.png";
import ltcIcon from "./coin-icons/icon_ltc.png";
import maticIcon from "./coin-icons/icon_matic.png";
import mkrIcon from "./coin-icons/icon_mkr.png";
import solIcon from "./coin-icons/icon_sol.png";
import umaIcon from "./coin-icons/icon_uma.png";
import uniIcon from "./coin-icons/icon_uni.png";
import usdcIcon from "./coin-icons/icon_usdc.png";
import usdtIcon from "./coin-icons/icon_usdt.png";
import xlmIcon from "./coin-icons/icon_xlm.png";
import xrpIcon from "./coin-icons/icon_xrp.png";
import { AskIcon, BidIcon, UpsDownIcon, LastIcon } from "./chart-icons/index";
import appleIcon from "./apple.png";
import gstoreIcon from "./gstore.png";
import BittrexMarketSummaryService from "../../services/bittrex-market-summary/BittrexMarketSummaryService";
import { formatNumber } from "../../services/utils/formatNumber";
import { detectStringType } from "../../services/utils/detectStringType";
import BittrexMarketSummary from "../../services/api/common/response/BittrexMarketSummary";
import TradeViewChart from "../crypto-graph/TradeViewChart";
import { Fees } from "../../services/api/common/response/Fees";
import FeesTickersService from "../../services/api/common/fees-tickers-service/FeesTickersService";
import { getCurrencySymbol } from "../../services/utils/cryptoSymbols";
import { Link, useHistory, useLocation } from "react-router-dom";
import BlogNewsScrapedArticles from "../../services/api/common/response/BlogNewsScrapedArticles";
import HttpClient from "../../services/api/HttpClient";
import { api } from "../../services/api/api";
import { formatDateForTable } from "../../services/utils/dateUtils";
import logoIcon from "../header/icons/lg.png";
import { LogoIcon } from "./logo";
import Select, { components } from "react-select";
import { Helmet } from "react-helmet";

const Dashboard = () => {
  // const coinData = [
  //     { name: 'AAVE', icon: aaveIcon },
  //     { name: 'ADA', icon: adaIcon },
  //     { name: 'APE', icon: apeIcon },
  //     { name: 'AVAX', icon: avaxIcon },
  //     { name: 'ALGO', icon: algoIcon },
  //     { name: 'ATOM', icon: atomIcon },
  //     { name: 'BTC', icon: btcIcon },
  //     { name: 'COMP', icon: compIcon },
  //     { name: 'DAI', icon: daiIcon },
  //     { name: 'DOGE', icon: dogeIcon },
  //     { name: 'DOT', icon: dotIcon },
  //     { name: 'ETH', icon: ethIcon },
  //     { name: 'GRT', icon: grtIcon },
  //     { name: 'LINK', icon: linkIcon },
  //     { name: 'LTC', icon: ltcIcon },
  //     { name: 'MATIC', icon: maticIcon },
  //     { name: 'MKR', icon: mkrIcon },
  //     { name: 'SOL', icon: solIcon },
  //     { name: 'UMA', icon: umaIcon },
  //     { name: 'UNI', icon: uniIcon },
  //     { name: 'USDC', icon: usdcIcon },
  //     { name: 'USDT', icon: usdtIcon },
  //     { name: 'XLM', icon: xlmIcon },
  //     { name: 'XRP', icon: xrpIcon },
  // ];

  const coinData = [
    { value: "ETH", label: "ETH", icon: ethIcon },
    { value: "AAVE", label: "AAVE", icon: aaveIcon },
    { value: "ADA", label: "ADA", icon: adaIcon },
    { value: "APE", label: "APE", icon: apeIcon },
    { value: "AVAX", label: "AVAX", icon: avaxIcon },
    { value: "ALGO", label: "ALGO", icon: algoIcon },
    { value: "ATOM", label: "ATOM", icon: atomIcon },
    { value: "BTC", label: "BTC", icon: btcIcon },
    { value: "COMP", label: "COMP", icon: compIcon },
    { value: "DAI", label: "DAI", icon: daiIcon },
    { value: "DOGE", label: "DOGE", icon: dogeIcon },
    { value: "DOT", label: "DOT", icon: dotIcon },
    { value: "GRT", label: "GRT", icon: grtIcon },
    { value: "LINK", label: "LINK", icon: linkIcon },
    { value: "LTC", label: "LTC", icon: ltcIcon },
    { value: "MATIC", label: "MATIC", icon: maticIcon },
    { value: "MKR", label: "MKR", icon: mkrIcon },
    { value: "SOL", label: "SOL", icon: solIcon },
    { value: "UMA", label: "UMA", icon: umaIcon },
    { value: "UNI", label: "UNI", icon: uniIcon },
    { value: "USDC", label: "USDC", icon: usdcIcon },
    { value: "XLM", label: "XLM", icon: xlmIcon },
    { value: "XRP", label: "XRP", icon: xrpIcon },
  ];

  const { Option } = components;
  const IconOption = (props: any) => (
    <Option {...props}>
      <img src={props.data.icon} style={{ width: 12 }} alt={props.data.label} />
      {props.data.label}
    </Option>
  );

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const coinSelectedParam = queryParams.get("selectedCoin") || "BTC";
  const coinSelectedIconParam = coinData.find((o) => o.value === coinSelectedParam) || {
    value: "BTC",
    label: "BTC",
    icon: btcIcon,
  };

  const history = useHistory();
  const [coinSelected, setCoinSelected] = useState(coinSelectedParam);
  const [coinSelectedIcon, setCoinSelectedIcon] = useState(coinSelectedIconParam);
  const [coinSelectedName, setCoinSelectedName] = useState(coinSelectedIconParam);

  const [coinSelectedBuy, setCoinSelectedBuy] = useState<any>(coinData[1]);

  const [coinSelectedSell, setCoinSelectedSell] = useState<any>(coinData[1]);

  const [detailedMarketSummaries, setDetailedMarketSummaries] =
    useState<Array<BittrexMarketSummary> | null>([]);
  const [coinBid, setCoinBid] = useState(0);
  const [coinAsk, setCoinAsk] = useState(0);
  const [coinLast, setCoinLast] = useState(0);
  const [coinChange1Hour, setCoinChange1Hour] = useState("");
  const [coinChange1Day, setCoinChange1Day] = useState("");
  const [coinChange7Days, setCoinChange7Days] = useState("");

  const [fees, setFees] = useState<Fees | null>(null);

  const [buyFee, setBuyFee] = useState(0);
  const [sellFee, setSellFee] = useState(0);

  const [linesOfCoins, setLinesOfCoins] = useState<any>([]);
  const screenWidth = window.innerWidth;

  const feesTickerService: FeesTickersService = FeesTickersService.getInstance();
  const marketSummaryService = BittrexMarketSummaryService.getInstance();

  const [buyPrice, setBuyPrice] = useState("");
  const [buyAmount, setBuyAmount] = useState("");
  const [buyTotal, setBuyTotal] = useState("");
  const [canPlaceBuyOrder, setCanPlaceBuyOrder] = useState(false);

  const [buyOrderAmountValidation, setBuyOrderAmountValidation] = useState("");
  const [buyOrderTotalAmountValidation, setBuyOrderTotalAmountValidation] = useState("");

  const [sellPrice, setSellPrice] = useState("");
  const [sellAmount, setSellAmount] = useState("");
  const [sellTotal, setSellTotal] = useState("");
  const [canPlaceSellOrder, setCanPlaceSellOrder] = useState(false);
  const [sellOrderAmountValidation, setSellOrderAmountValidation] = useState("");
  const [sellOrderTotalAmountValidation, setSellOrderTotalAmountValidation] = useState("");

  const [usdtBalance, setUsdtBalance] = useState(10000);
  const [coinBalance, setCoinBalance] = useState(5);

  const [scrapedArticles, setScrapedArticles] = useState<Array<BlogNewsScrapedArticles>>([]);

  const httpClient = new HttpClient();

  useEffect(() => {
    const fees = feesTickerService.getFees();
    if (fees) {
      setFees(fees);
    }

    const fetchScrapedArticlesMethod = async () => {
      const scrapedArticlesResponse = await httpClient.get<Array<BlogNewsScrapedArticles>>(
        api.common.LATEST_BLOG_NEWS
      );
      if (scrapedArticlesResponse.status === 200) {
        setScrapedArticles(scrapedArticlesResponse.data);
      }
    };

    fetchScrapedArticlesMethod().then(() => {});
  }, [setScrapedArticles]); // Empty dependency array means the effect runs only once

  useEffect(() => {
    console.log(scrapedArticles, "scrapedArticles");

    // Calculate the number of lines and coins per line based on screen width
    let numLines, coinsPerLine;
    if (screenWidth >= 992) {
      numLines = 3;
      coinsPerLine = Math.ceil(coinData.length / numLines);
    } else {
      numLines = 6;
      coinsPerLine = 4;
    }

    // Generate lines with coins
    const linesArray = [];
    for (let i = 0; i < numLines; i++) {
      const startIdx = i * coinsPerLine;
      const endIdx = startIdx + coinsPerLine;
      const lineCoins = coinData.slice(startIdx, endIdx);

      linesArray.push(
        <div className="trade-coin-container-line" key={i}>
          {lineCoins.map((coin) => (
            <div
              key={coin.value}
              className={`trade-container-coin`}
              style={{
                borderRadius: "8.283px",
                border: coinSelected === coin.value ? "1px solid #013B37" : "1px solid #00000014",
              }}
              onClick={() => {
                setCoinSelected(coin.value);
                setCoinSelectedIcon(coin);
                setCoinSelectedName(coin);
                setCoinSelectedBuy(coin);
                setCoinSelectedSell(coin);
                setBottomBuyCurrencySelector(coin);
                setBottomSellCurrencySelector(coin);
              }}
            >
              <img src={coin.icon} alt="ic" className="trade-icon" />
              <span className="trade-container-coin-text">{coin.value}</span>
            </div>
          ))}
          {i === numLines - 1 && (
            <div className="trade-container-coin" style={{ cursor: "default" }}>
              <img src={xlmIcon} alt="ic" className="trade-icon" style={{ display: "none" }} />
              <span className="trade-container-coin-text" style={{ display: "none" }}>
                none
              </span>
            </div>
          )}
        </div>
      );
    }
    setLinesOfCoins(linesArray);
  }, [coinSelected, buyFee, sellFee]);

  useEffect(() => {
    const fetchMarketData = async () => {
      const summaries = marketSummaryService.getMarketDetailedSummaries();
      setDetailedMarketSummaries(summaries);
    };

    fetchMarketData().then(() => {});

    const intervalId = setInterval(fetchMarketData, 1000);

    resetForm();

    return () => {
      clearInterval(intervalId);
    };
  }, [coinSelected, buyFee, sellFee]);

  useEffect(() => {
    // console.log(detailedMarketSummaries, "detailedMarketSummaries");
    const marketSummary = detailedMarketSummaries?.find(
      (summary) => summary.market === coinSelected
    );
    if (marketSummary) {
      setCoinAsk(marketSummary.ask);
      setCoinBid(marketSummary.bid);
      setCoinLast(marketSummary.last);
      setCoinChange1Hour(
        marketSummary.percentChange1h >= 0
          ? "+" + formatNumber(marketSummary.percentChange1h).toString()
          : formatNumber(marketSummary.percentChange1h).toString()
      );
      setCoinChange1Day(
        marketSummary.percentChange24h >= 0
          ? "+" + formatNumber(marketSummary.percentChange24h).toString()
          : formatNumber(marketSummary.percentChange24h).toString()
      );
      setCoinChange7Days(
        marketSummary.percentChange7d >= 0
          ? "+" + formatNumber(marketSummary.percentChange7d).toString()
          : formatNumber(marketSummary.percentChange7d).toString()
      );
    }

    if (fees) {
      setBuyFee(fees.currencies[coinSelected].orderBuy);
      setSellFee(fees.currencies[coinSelected].orderSell);
    }
  }, [coinSelected, detailedMarketSummaries, fees, buyFee, sellFee]);

  const resetForm = () => {
    setBuyTotal("");
    setBuyPrice("");
    setBuyAmount("");
    setBuyOrderAmountValidation("");
    setBuyOrderTotalAmountValidation("");
    setCanPlaceBuyOrder(false);

    setSellTotal("");
    setSellPrice("");
    setSellAmount("");
    setSellOrderAmountValidation("");
    setSellOrderTotalAmountValidation("");
    setCanPlaceSellOrder(false);
  };

  const handleBuyPriceChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    setBuyPrice(event.target.value);
  };

  const handleBuyAmountChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    setBuyAmount(event.target.value);
  };

  const handleBuyTotalChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    setBuyTotal(event.target.value);
  };

  const handleSellPriceChanged = (event: any) => {
    setSellPrice(event.target.value);
  };

  const handleSellAmountChanged = (event: any) => {
    setSellAmount(event.target.value);
  };

  const handleSellTotalChanged = (event: any) => {
    setSellTotal(event.target.value);
  };

  const calculateTotal = (price: number, amount: number) => {
    const total = Math.floor(price * amount * 1e6) / 1e6; // Round to 6 decimals
    return total.toString();
  };

  const calculateAmount = (price: number, total: number) => {
    const amount = Math.floor((total / price) * 1e6) / 1e6; // Round to 6 decimals
    return amount.toString();
  };

  const calculatePrice = (total: number, amount: number) => {
    const price = Math.floor((total / amount) * 1e6) / 1e6; // Round to 6 decimals
    return price.toString();
  };

  const getMinCryptoAmountToMeetMinTradeVolume = (): number => {
    const SATOSHI_MULTIPLIER: number = 100000000;
    const minTradeValueInSatoshi: number =
      feesTickerService.getMinTradeValueInSatoshi().minTradeValueInSatoshi + 1500;
    const minBtcAmount: number = minTradeValueInSatoshi / SATOSHI_MULTIPLIER;
    const oneBtcInUsdt: number =
      marketSummaryService.getMarketDetailedSummary("USDT-BTC")?.last || 0;

    const minAmountInUsdt = minBtcAmount * oneBtcInUsdt;
    const oneSelectedCoinInUsdt =
      marketSummaryService.getMarketDetailedSummary("USDT-" + coinSelected)?.last || 0;
    return Math.ceil((minAmountInUsdt / oneSelectedCoinInUsdt) * 1e6) / 1e6;
  };

  useEffect(() => {
    if (buyPrice !== "" && buyAmount !== "") {
      const newBuyTotal = calculateTotal(parseFloat(buyPrice), parseFloat(buyAmount));
      if (!isNaN(parseFloat(newBuyTotal))) {
        setBuyTotal(newBuyTotal);
      }
    } else if (buyPrice !== "" && buyTotal !== "") {
      const newBuyAmount = calculateAmount(parseFloat(buyPrice), parseFloat(buyTotal));
      if (!isNaN(parseFloat(newBuyAmount))) {
        setBuyAmount(newBuyAmount);
      }
    } else if (buyAmount !== "" && buyTotal !== "") {
      const newBuyPrice = calculatePrice(parseFloat(buyTotal), parseFloat(buyAmount));
      if (!isNaN(parseFloat(newBuyPrice))) {
        setBuyPrice(newBuyPrice);
      }
    }

    setBuyOrderAmountValidation("");
    setBuyOrderTotalAmountValidation("");

    setCanPlaceBuyOrder(
      parseFloat(buyPrice) > 0 && parseFloat(buyAmount) > 0 && parseFloat(buyTotal) > 0
    );
  }, [buyPrice, buyAmount, buyTotal]);

  useEffect(() => {
    if (sellPrice !== "" && sellAmount !== "") {
      const newSellTotal = calculateTotal(parseFloat(sellPrice), parseFloat(sellAmount));
      if (!isNaN(parseFloat(newSellTotal))) {
        setSellTotal(newSellTotal);
      }
    } else if (sellPrice !== "" && sellTotal !== "") {
      const newSellAmount = calculateAmount(parseFloat(sellPrice), parseFloat(sellTotal));
      if (!isNaN(parseFloat(newSellAmount))) {
        setSellAmount(newSellAmount);
      }
    } else if (sellAmount !== "" && sellTotal !== "") {
      const newSellPrice = calculatePrice(parseFloat(sellTotal), parseFloat(sellAmount));
      if (!isNaN(parseFloat(newSellPrice))) {
        setSellPrice(newSellPrice);
      }
    }

    setSellOrderAmountValidation("");
    setSellOrderTotalAmountValidation("");

    setCanPlaceSellOrder(
      parseFloat(sellPrice) > 0 && parseFloat(sellAmount) > 0 && parseFloat(sellTotal) > 0
    );
  }, [sellPrice, sellAmount, sellTotal]);

  const placeBuyOrder = async (event: React.MouseEvent<HTMLButtonElement>) => {
    window.open("https://app.vtrader.io/", "_blank");
  };

  const placeSellOrder = async (event: React.MouseEvent<HTMLButtonElement>) => {
    window.open("https://app.vtrader.io/", "_blank");
  };

  const { selectedOption }: any = coinData[0].value;

  const [topBuyCurrencySelector, setTopBuyCurrencySelector] = useState<any>(coinData[0]);
  const [bottomBuyCurrencySelector, setBottomBuyCurrencySelector] = useState<any>(coinData[1]);
  const [topSellCurrencySelector, setTopSellCurrencySelector] = useState<any>(coinData[0]);
  const [bottomSellcurrencySelector, setBottomSellCurrencySelector] = useState<any>(coinData[1]);

  const skeletonTradeValues = () => {
    return (
      <ContentLoader
        speed={2}
        width={80}
        height={20}
        viewBox="0 0 80 20"
        backgroundColor="#c8e4db"
        foregroundColor="#e0f3ed"
      >
        <rect x="10" y="5" rx="3" ry="3" width="70" height="6" />
        {/* <rect x="10" y="10" rx="3" ry="3" width="50" height="6" /> */}
      </ContentLoader>
    );
  };

  const skeletonTradeValuesPercentage = () => {
    return (
      <ContentLoader
        speed={2}
        width={90}
        height={20}
        viewBox="0 0 90 20"
        backgroundColor="#c8e4db"
        foregroundColor="#e0f3ed"
      >
        <rect x="0" y="5" rx="3" ry="3" width="90" height="6" />
        {/* <rect x="0" y="10" rx="3" ry="3" width="70" height="6" /> */}
      </ContentLoader>
    );
  };

  const skeletonBlogs = (viewBoxWidth: number = 5000, numberOfElements: number = 15) => {
    return (
      <ContentLoader
        viewBox={`0 0 ${viewBoxWidth} 60`}
        width={viewBoxWidth}
        height={60}
        title="Loading news..."
        backgroundColor="#c8e4db"
        foregroundColor="#e0f3ed"
      >
        {[...Array(numberOfElements)].map((_, i) => {
          const baseX = i * 350;
          return (
            <React.Fragment key={i}>
              <rect x={baseX + 42.84} y="9.93" rx="5" ry="5" width="77.746" height="51" />
              <rect x={baseX + 140} y="9.67" rx="0" ry="0" width="78" height="15" />
              <rect x={baseX + 140} y="31" rx="0" ry="0" width="196" height="26" />
            </React.Fragment>
          );
        })}
      </ContentLoader>
    );
  };

  return (
    <div>
      <Helmet>
        <title>Vtrader IO - A Digital Asset Exchange</title>
      </Helmet>
      <div className="articles">
        <div className="article-menu-items">
          {/* <a href="https://vtrader.io/news/" className="article-menu-item"> */}
          <p className="article-menu-item-text">Recent</p>
          {/* </a> */}
          <a href="https://vtrader.io/news/" className="article-menu-item">
            <p className="article-menu-item-text">Articles</p>
          </a>
          <a href="https://vtrader.io/news/" className="article-menu-item">
            <p className="article-menu-item-text view-all">
              View All
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="3"
                height="7"
                viewBox="0 0 3 7"
                fill="none"
              >
                <path d="M3 3.5L-4.70644e-08 6.53109L2.17922e-07 0.468911L3 3.5Z" fill="#00A05A" />
              </svg>
            </p>
          </a>
        </div>
        {scrapedArticles.length > 0 ? (
          <div className="list-of-articles">
            <Marquee pauseOnHover={true} speed={28} autoFill>
              {scrapedArticles.map((article, index) => (
                <div
                  className="blog-article-box"
                  key={index}
                  onClick={() => {
                    window.open(article.url, "_blank");
                  }}
                >
                  {" "}
                  {/* Use `key` prop with a unique value, e.g., `index` */}
                  <img
                    src={`data:${article.imageMimeType};base64,${article.imageData}`}
                    alt={article.title}
                    className="blog-article-image-box"
                  />
                  <div className="blog-article-titles-container">
                    <p className="blog-article-title-date" style={{ marginBottom: "-3px" }}>
                      {formatDateForTable(article.date)}
                    </p>
                    <p className="blog-article-title-text">{article.title}</p>
                  </div>
                </div>
              ))}
            </Marquee>
          </div>
        ) : // Render the skeleton
        screenWidth < 767 ? (
          skeletonBlogs(767, 3)
        ) : screenWidth < 1025 ? (
          skeletonBlogs(1025, 6)
        ) : screenWidth < 2000 ? (
          skeletonBlogs(2000, 10)
        ) : (
          skeletonBlogs()
        )}

        {/* {scrapedArticles && screenWidth < 600 && (<div>
                    <a href="https://vtrader.io/news" target="_blank" style={{ textDecoration: 'none', color: 'black' }}>
                        <h2>Latest News</h2></a>
                    <div className="blog-articles-container">
                        {scrapedArticles.slice(0, 3).map((article, index) => (
                            <div className="blog-article-box" key={index} onClick={() => {
                                window.open(article.url, "_blank");
                            }}>  `key` prop with a unique value, e.g., `index` 
                                <img
                                    src={`data:${article.imageMimeType};base64,${article.imageData}`}
                                    alt={article.title}
                                    className="blog-article-image-box"
                                />
                                <div>
                                    <p className="blog-article-title-date"
                                        style={{ marginBottom: '-3px' }}>{formatDateForTable(article.date)}</p>
                                    <p className="blog-article-title-text">{article.title}</p>
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className="blog-articles-container">
                        {scrapedArticles.slice(3, 6).map((article, index) => (
                            <div className="blog-article-box" key={index} onClick={() => {
                                window.open(article.url, "_blank");
                            }}> Use `key` prop with a unique value, e.g., `index`
                                <img
                                    src={`data:${article.imageMimeType};base64,${article.imageData}`}
                                    alt={article.title}
                                    className="blog-article-image-box"
                                />
                                <p className="blog-article-title-date"
                                    style={{ marginBottom: '-3px' }}>{formatDateForTable(article.date)}</p>
                                <p className="blog-article-title-text">{article.title}</p>
                            </div>
                        ))}
                    </div>
                </div>)} */}
      </div>
      <div className="dashboard-container" style={{ marginTop: "-25px", marginBottom: "50px" }}>
        {/* GARDIENT COLOR */}
        <div className="green-gardient-bg">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="1920"
            height="807"
            viewBox="0 0 1920 807"
            fill="none"
          >
            <g opacity="0.4" filter="url(#filter0_f_2_107)">
              <path
                d="M825.596 111.336C1468.78 -145.254 1963.8 54.1374 1966.13 247.46C1968.47 440.783 1823.12 319.684 1416.75 324.59C1010.37 329.497 -265.634 443.105 -267.968 249.782C-270.302 56.4595 -89.6682 363.628 825.596 111.336Z"
                fill="url(#paint0_radial_2_107)"
                fillOpacity="0.85"
              />
            </g>
            <defs>
              <filter
                id="filter0_f_2_107"
                x="-711.99"
                y="-454.412"
                width="3122.15"
                height="1260.6"
                filterUnits="userSpaceOnUse"
                colorInterpolationFilters="sRGB"
              >
                <feFlood floodOpacity="0" result="BackgroundImageFix" />
                <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                <feGaussianBlur stdDeviation="222" result="effect1_foregroundBlur_2_107" />
              </filter>
              <radialGradient
                id="paint0_radial_2_107"
                cx="0"
                cy="0"
                r="1"
                gradientUnits="userSpaceOnUse"
                gradientTransform="translate(954.624 288.229) rotate(12.9374) scale(878.598 379.12)"
              >
                <stop stopColor="#8ECCB1" />
                <stop stopColor="#8ECCB1" />
                <stop offset="0.370285" stopColor="#00A05A" />
                <stop offset="0.66901" stopColor="#9AE7FF" />
                <stop offset="0.86385" stopColor="#3C7B60" />
                <stop offset="1" stopColor="#A8F6DC" />
              </radialGradient>
            </defs>
          </svg>
        </div>
        <div className="logo">
          <LogoIcon />
        </div>

        <div className="trade-coin-container">
          {/* {linesOfCoins} */}
          {coinData.map((coin) => (
            <>
              <div
                key={coin.value}
                className={`trade-container-coin`}
                style={{
                  borderRadius: "8.283px",
                  border: coinSelected === coin.value ? "1px solid #013B37" : "1px solid #00000014",
                }}
                onClick={() => {
                  setCoinSelected(coin.value);
                  setCoinSelectedIcon(coin);
                  setCoinSelectedName(coin);
                  setCoinSelectedBuy(coin);
                  setCoinSelectedSell(coin);
                  setBottomBuyCurrencySelector(coin);
                  setBottomSellCurrencySelector(coin);
                }}
              >
                <img src={coin.icon} alt="ic" className="trade-icon" />
                <span className="trade-container-coin-text">{coin.value}</span>
              </div>
            </>
          ))}
        </div>

        <div className="trade-view-chart">
          {/* for USDT & UMA symbol/coins, the currency is different but Exchange is 'Bittrex' used. */}
          <TradeViewChart
            tradingPair={`${
              coinSelected === "USDT"
                ? "USDTUSD"
                : coinSelected === "UMA"
                ? "UMABTC"
                : coinSelected + "USDT"
            }`}
            width={screenWidth < 695 ? screenWidth - 30 : 570.892}
          />
          <div className="trade-ask-bid-container">
            <div className="trade-ask-bid-container-box">
              <AskIcon />
              <span className="trade-ask-bid-info">
                <p className="trade-ask-bid-container-box-title">Ask</p>
                <p className="trade-ask-bid-container-box-value">
                  ${coinAsk >= 0 ? formatNumber(coinAsk) : skeletonTradeValues()}
                </p>
              </span>
            </div>
            <div className="trade-ask-bid-container-box">
              <BidIcon />
              <span className="trade-ask-bid-info">
                <p className="trade-ask-bid-container-box-title">Bid</p>
                <p className="trade-ask-bid-container-box-value">
                  ${coinBid >= 0 ? formatNumber(coinBid) : skeletonTradeValues()}
                </p>
              </span>
            </div>
            <div className="trade-ask-bid-container-box">
              <LastIcon />
              <span className="trade-ask-bid-info">
                <p className="trade-ask-bid-container-box-title">Last</p>
                <p className="trade-ask-bid-container-box-value">
                  ${coinLast >= 0 ? formatNumber(coinLast) : skeletonTradeValues()}
                </p>
              </span>
            </div>
            <div className="trade-ask-bid-container-box">
              <UpsDownIcon />
              <span className="trade-ask-bid-info">
                <p className="trade-ask-bid-container-box-title">1H</p>
                <p
                  className={`trade-ask-bid-container-box-value ${
                    detectStringType(coinChange1Hour) ? "positive-value" : "negative-value"
                  }`}
                >
                  {coinChange1Hour ? coinChange1Hour + "%" : skeletonTradeValuesPercentage()}
                </p>
              </span>
            </div>
            <div className="trade-ask-bid-container-box">
              <UpsDownIcon />
              <span className="trade-ask-bid-info">
                <p className="trade-ask-bid-container-box-title">1D</p>
                <p
                  className={`trade-ask-bid-container-box-value ${
                    detectStringType(coinChange1Day) ? "positive-value" : "negative-value"
                  }`}
                >
                  {coinChange1Day ? coinChange1Day + "%" : skeletonTradeValuesPercentage()}
                </p>
              </span>
            </div>
            <div className="trade-ask-bid-container-box">
              <UpsDownIcon />
              <span className="trade-ask-bid-info">
                <p className="trade-ask-bid-container-box-title">7D</p>
                <p
                  className={`trade-ask-bid-container-box-value ${
                    detectStringType(coinChange7Days) ? "positive-value" : "negative-value"
                  }`}
                >
                  {coinChange7Days ? coinChange7Days + "%" : skeletonTradeValuesPercentage()}
                </p>
              </span>
            </div>
          </div>
        </div>

        <div
          className="rectangle"
          style={{ backgroundColor: "#DDD", marginBottom: "20px", marginTop: "20px" }}
        ></div>

        <div className="trade-ask-bid-container-buy-sell-coin">
          <div className="trade-ask-bid-container-buy-sell-coin-column">
            <div className="form-title-icon">
              <img src={coinSelectedBuy.icon} alt="ic" className="trade-icon" />
            </div>

            <p className="trade-ask-bid-container-buy-sell-coin-p-buy-sell">
              BUY {coinSelectedBuy.value}
            </p>

            <form>
              <div className="buy_sell-form-input-wrapper">
                <input
                  type="number"
                  title="Price (USDT)"
                  placeholder="Price (USDT)"
                  value={buyPrice}
                  onChange={handleBuyPriceChanged}
                />
                <div className="buy_sell-form-input-child-wrapper">
                  <div className="buy_sell-form-input-image">
                    <img src={topBuyCurrencySelector?.icon} alt="" />
                  </div>
                  <Select
                    className="single_select_form_input"
                    components={{ Option: IconOption }}
                    defaultValue={coinData[0]}
                    value={selectedOption}
                    onChange={(selectedOptionNew, selectedOption) => {
                      setTopBuyCurrencySelector(selectedOptionNew);

                      // console.log(`Option selected:`, selectedOptionNew, selectedOption)
                    }}
                  />
                </div>
              </div>

              <div className="buy_sell-form-input-wrapper">
                <input
                  type="number"
                  title={`Amount (${coinSelected})`}
                  placeholder={`Amount (${coinSelectedBuy.value})`}
                  value={buyAmount}
                  onChange={handleBuyAmountChanged}
                />
                {buyOrderAmountValidation && buyAmount && (
                  <div className="order-validation-message">
                    {buyOrderAmountValidation}
                    <span
                      style={{
                        color: "black",
                        textDecoration: "underline",
                        cursor: "pointer",
                        fontSize: "10px",
                      }}
                      onClick={() => {
                        setBuyAmount(getMinCryptoAmountToMeetMinTradeVolume().toString());
                      }}
                    >
                      {getCurrencySymbol(coinSelected) +
                        getMinCryptoAmountToMeetMinTradeVolume().toString()}
                    </span>
                  </div>
                )}
                <div className="buy_sell-form-input-child-wrapper">
                  <div className="buy_sell-form-input-image">
                    <img src={bottomBuyCurrencySelector?.icon} alt="" />
                  </div>

                  <Select
                    options={coinData}
                    components={{ Option: IconOption }}
                    defaultValue={coinData[1]}
                    value={coinSelectedBuy}
                    onChange={(selectedOptionNew, selectedOption) => {
                      setBottomBuyCurrencySelector(selectedOptionNew);
                      setCoinSelectedBuy(selectedOptionNew);
                    }}
                  />
                </div>
              </div>

              <input
                type="number"
                className="buy_total_input"
                title="Total (USDT)"
                placeholder="Total (USDT)"
                value={buyTotal}
                onChange={handleBuyTotalChanged}
              />
              {buyOrderTotalAmountValidation && buyTotal && (
                <div className="order-validation-message">
                  {buyOrderTotalAmountValidation} You have&nbsp;
                  <span
                    style={{
                      color: "black",
                      textDecoration: "underline",
                      cursor: "pointer",
                      fontSize: "10px",
                    }}
                    onClick={() => {
                      setBuyAmount("");
                      setBuyTotal(usdtBalance.toString());
                    }}
                  >
                    {getCurrencySymbol(coinSelected) + formatNumber(usdtBalance)}
                  </span>
                </div>
              )}
              <button
                className="trade-button"
                style={{
                  backgroundColor: !canPlaceBuyOrder ? "#BBB" : "#111",
                  cursor: "pointer",
                }}
                onClick={placeBuyOrder}
              >
                BUY
              </button>
            </form>

            <div className="coin-text-wrapper">
              <p
                style={{ marginBottom: "-2px", cursor: "pointer" }}
                className="trade-ask-bid-container-buy-sell-coin-text"
              >
                Ask:&nbsp;
                <span
                  style={{
                    cursor: "pointer",
                    textDecoration: "underline",
                  }}
                  onClick={() => {
                    setBuyPrice(coinAsk.toString());
                  }}
                >
                  {getCurrencySymbol("USDT") + formatNumber(coinAsk)}
                </span>
              </p>
              <p
                style={{ marginBottom: "-2px", cursor: "pointer" }}
                className="trade-ask-bid-container-buy-sell-coin-text"
              >
                You Have:{" "}
                <span
                  style={{
                    cursor: "pointer",
                    textDecoration: "underline",
                  }}
                  onClick={() => {
                    setBuyAmount("");
                    setBuyTotal(usdtBalance.toString());
                  }}
                >
                  {getCurrencySymbol("USDT") + formatNumber(usdtBalance)}
                </span>
              </p>
              <p className="trade-ask-bid-container-buy-sell-coin-text">Fee: 0.85%</p>
            </div>

            <p
              className="trade-ask-bid-container-buy-sell-coin-text"
              style={{
                textDecoration:
                  buyTotal !== "" || buyPrice !== "" || buyAmount !== "" ? "underline" : "inherit",
                cursor:
                  buyTotal !== "" || buyPrice !== "" || buyAmount !== "" ? "pointer" : "inherit",
                fontWeight:
                  buyTotal !== "" || buyPrice !== "" || buyAmount !== "" ? "bold" : "inherit",
                color: buyTotal !== "" || buyPrice !== "" || buyAmount !== "" ? "black" : "white",
                alignSelf: "baseline",
                paddingLeft: "10px",
              }}
              onClick={() => {
                if (buyTotal !== "" || buyPrice !== "" || buyAmount !== "") {
                  setBuyTotal("");
                  setBuyPrice("");
                  setBuyAmount("");
                }
              }}
            >
              Reset Form
            </p>
          </div>

          {screenWidth < 600 && (
            <div
              className="rectangle"
              style={{
                backgroundColor: "#DDD",
                marginBottom: "30px",
                marginTop: "60px",
              }}
            ></div>
          )}

          <div className="trade-ask-bid-container-buy-sell-coin-column">
            <div className="form-title-icon">
              <img src={coinSelectedSell.icon} alt="ic" className="trade-icon" />
            </div>
            <p className="trade-ask-bid-container-buy-sell-coin-p-buy-sell">
              SELL {coinSelectedSell.value}
            </p>

            <form>
              <div className="buy_sell-form-input-wrapper">
                <input
                  type="number"
                  title="Price (USDT)"
                  placeholder="Price (USDT)"
                  value={sellPrice}
                  onChange={handleSellPriceChanged}
                />
                <div className="buy_sell-form-input-child-wrapper">
                  <div className="buy_sell-form-input-image">
                    <img src={topSellCurrencySelector?.icon} alt="" />
                  </div>

                  <Select
                    className="single_select_form_input"
                    components={{ Option: IconOption }}
                    defaultValue={coinData[0]}
                    value={selectedOption}
                    onChange={(selectedOptionNew, selectedOption) => {
                      setTopSellCurrencySelector(selectedOptionNew);

                      // console.log(`Option selected:`, selectedOptionNew, selectedOption)
                    }}
                  />
                </div>
              </div>
              <div className="buy_sell-form-input-wrapper">
                <input
                  type="number"
                  title={`Amount (${coinSelected})`}
                  placeholder={`Amount (${coinSelectedSell.value})`}
                  value={sellAmount}
                  onChange={handleSellAmountChanged}
                />
                {sellOrderAmountValidation && sellAmount && (
                  <div className="order-validation-message">
                    {sellOrderAmountValidation}
                    You have&nbsp;
                    <span
                      style={{
                        color: "black",
                        textDecoration: "underline",
                        cursor: "pointer",
                        fontSize: "10px",
                      }}
                      onClick={() => {
                        setSellAmount(coinBalance.toString());
                      }}
                    >
                      {getCurrencySymbol(coinSelected) + formatNumber(coinBalance)}
                    </span>
                  </div>
                )}
                {sellOrderTotalAmountValidation && sellAmount && (
                  <div className="order-validation-message">
                    {sellOrderTotalAmountValidation}
                    <span
                      style={{
                        color: "black",
                        textDecoration: "underline",
                        cursor: "pointer",
                        fontSize: "10px",
                      }}
                      onClick={() => {
                        setSellAmount(getMinCryptoAmountToMeetMinTradeVolume().toString());
                      }}
                    >
                      {getCurrencySymbol(coinSelected) +
                        getMinCryptoAmountToMeetMinTradeVolume().toString()}
                    </span>
                  </div>
                )}
                <div className="buy_sell-form-input-child-wrapper">
                  <div className="buy_sell-form-input-image">
                    <img src={bottomSellcurrencySelector?.icon} alt="" />
                  </div>

                  <Select
                    options={coinData}
                    components={{ Option: IconOption }}
                    defaultValue={coinData[1]}
                    value={coinSelectedSell}
                    onChange={(selectedOptionNew, selectedOption) => {
                      setBottomSellCurrencySelector(selectedOptionNew);
                      setCoinSelectedSell(selectedOptionNew);
                    }}
                  />
                </div>
              </div>
              <input
                type="number"
                className="sell_total_input"
                title="Total (USDT)"
                placeholder="Total (USDT)"
                value={sellTotal}
                onChange={handleSellTotalChanged}
              />
              <button
                className="trade-button"
                style={{
                  backgroundColor: !canPlaceSellOrder ? "#BBB" : "#111",
                  cursor: "pointer",
                }}
                onClick={placeSellOrder}
              >
                SELL
              </button>
            </form>
            <div className="coin-text-wrapper">
              <p
                style={{
                  marginBottom: "-2px",
                  cursor: "pointer",
                }}
                className="trade-ask-bid-container-buy-sell-coin-text"
              >
                Bid:&nbsp;
                <span
                  onClick={() => {
                    setSellPrice(coinBid.toString());
                  }}
                  style={{
                    cursor: "pointer",
                    textDecoration: "underline",
                  }}
                >
                  {getCurrencySymbol("USDT") + formatNumber(coinBid)}
                </span>
              </p>
              <p
                style={{
                  marginBottom: "-2px",
                  cursor: "pointer",
                }}
                className="trade-ask-bid-container-buy-sell-coin-text"
              >
                You Have:{" "}
                <span
                  onClick={() => {
                    setSellAmount(coinBalance.toString());
                  }}
                  style={{
                    cursor: "pointer",
                    textDecoration: "underline",
                  }}
                >
                  {getCurrencySymbol(coinSelected) + formatNumber(coinBalance)}{" "}
                </span>
              </p>
              <p className="trade-ask-bid-container-buy-sell-coin-text">Fee: 0.85%</p>
            </div>

            <p
              className="trade-ask-bid-container-buy-sell-coin-text"
              style={{
                textDecoration:
                  sellTotal !== "" || sellPrice !== "" || sellAmount !== ""
                    ? "underline"
                    : "inherit",
                cursor:
                  sellTotal !== "" || sellPrice !== "" || sellAmount !== "" ? "pointer" : "inherit",
                fontWeight:
                  sellTotal !== "" || sellPrice !== "" || sellAmount !== "" ? "bold" : "inherit",
                alignSelf: "baseline",
                color:
                  sellTotal !== "" || sellPrice !== "" || sellAmount !== "" ? "black" : "white",
                paddingLeft: "10px",
              }}
              onClick={() => {
                if (sellTotal !== "" || sellPrice !== "" || sellAmount !== "") {
                  setSellTotal("");
                  setSellPrice("");
                  setSellAmount("");
                }
              }}
            >
              Reset Form
            </p>
          </div>
        </div>

        <div
          className="rectangle"
          style={{ backgroundColor: "#DDD", marginBottom: "50px", marginTop: "0px" }}
        ></div>

        {/* <div className="rectangle"
                    style={{ backgroundColor: "#DDD", marginBottom: '40px', marginTop: '20px' }}></div>

                <button className="trade-button"
                    style={{
                        width: '100%',
                        cursor: 'pointer'
                    }}
                    onClick={() => {
                        window.scrollTo(0, 0);
                        history.push('/academy')
                    }}>VTRADER ACADEMY
                </button>

                <div className="rectangle"
                    style={{ backgroundColor: "#DDD", marginBottom: '20px', marginTop: '40px' }}></div>

                <h4 style={{ textAlign: 'center' }}>ETH gas free tracker mobile app</h4>
                <div className="mobile-activity-apps-container">
                    <div className="mobile-activity-app-button"
                        onClick={() => {
                            window.open('https://apps.apple.com/ro/app/ethereum-gas-tracker-alerts/id6449078810', '_blank');
                        }}>
                        <div className="mobile-activity-app-icon-and-text-container">
                            <img src={appleIcon} className="mobile-activity-icon" />
                            <p className="mobile-activity-app-text">APPLE APP STORE</p>
                        </div>

                    </div>
                    <div className="mobile-activity-app-button"
                        onClick={() => {
                            window.open('https://play.google.com/store/apps/details?id=io.vtrader.ethereumstats&hl=en&gl=US', '_blank');
                        }}>
                        <div className="mobile-activity-app-icon-and-text-container">
                            <img src={gstoreIcon} className="mobile-activity-icon" />
                            <p className="mobile-activity-app-text">GOOGLE PLAY</p>
                        </div>
                    </div>
                </div> */}
      </div>
    </div>
  );
};

export default Dashboard;
