import HttpClient from "../api/HttpClient";
import { api } from "../api/api";
import MarketData from "../api/common/response/BittrexMarketPrice";
import BittrexMarketSummary from "../api/common/response/BittrexMarketSummary";
import MarketRequestViewModel from "../api/common/request/MarketRequestViewModel";
import BittrexMarketOhlcv from "../api/common/response/BittrexMarketOhlcv";

class BittrexMarketSummaryService {
  private static instance: BittrexMarketSummaryService | null = null;

  private httpClient: HttpClient = new HttpClient();
  private marketSummaries: MarketData | null = null;
  private detailedMarketSummaries: Array<BittrexMarketSummary> | null = null;
  private marketOhlcvs: Map<string, Array<BittrexMarketOhlcv>> = new Map<
    string,
    Array<BittrexMarketOhlcv>
  >();

  private static cryptocurrencies = [
    "ETH",
    "BTC",
    "LTC",
    "XRP",
    "ADA",
    "DOGE",
    "LINK",
    "COMP",
    "AAVE",
    "UNI",
    "XLM",
    "DAI",
    "GRT",
    "MKR",
    "UMA",
    "DOT",
    "USDC",
    "SOL",
    "MATIC",
    "ATOM",
    "ALGO",
    "AVAX",
    "APE",
  ];

  private static usdtPairs = this.cryptocurrencies.map((crypto) => `USDT-${crypto}`);

  private constructor() {}

  static getInstance(): BittrexMarketSummaryService {
    if (!BittrexMarketSummaryService.instance) {
      BittrexMarketSummaryService.instance = new BittrexMarketSummaryService();
    }
    return BittrexMarketSummaryService.instance;
  }

  getMarketSummaries() {
    return this.marketSummaries;
  }

  getMarketDetailedSummary(market: string) {
    if (this.detailedMarketSummaries) {
      const marketSummary = this.detailedMarketSummaries.find(
        (summary) => summary.market === market
      );
      return marketSummary || null;
    }
    return null;
  }

  getMarketDetailedSummaries() {
    return this.detailedMarketSummaries;
  }

  getMarketOhlcv(market: string) {
    return this.marketOhlcvs.get(market);
  }

  private async fetchMarketSummaries() {
    try {
      const response = await this.httpClient.get<any>(api.common.MARKET_SUMMARIES);
      this.marketSummaries = response.data;
    } catch (error) {
      // Handle errors
    }
  }

  private async fetchMarketOhlc(market: string) {
    try {
      const marketRequestViewModel: MarketRequestViewModel = new MarketRequestViewModel();
      marketRequestViewModel.market = market;
      const response = await this.httpClient.post<
        MarketRequestViewModel,
        Array<BittrexMarketOhlcv>
      >(api.common.MARKET_OHLC, marketRequestViewModel);
      return response.data;
    } catch (error) {}
  }

  private async fetchMarketDetailedSummary(market: string) {
    try {
      const marketRequestViewModel: MarketRequestViewModel = new MarketRequestViewModel();
      marketRequestViewModel.market = market;
      const response = await this.httpClient.post<MarketRequestViewModel, BittrexMarketSummary>(
        api.common.MARKET_SUMMARY,
        marketRequestViewModel
      );
      return response.data;
    } catch (error) {
      return null;
    }
  }

  private async fetchMarketDetailedSummaries() {
    const marketSummaries: BittrexMarketSummary[] = [];

    for (const usdtPair of BittrexMarketSummaryService.usdtPairs) {
      const summary = await this.fetchMarketDetailedSummary(usdtPair);
      if (summary) {
        marketSummaries.push(summary);
      }
    }

    this.detailedMarketSummaries = marketSummaries;
  }

  private async fetchMarketOhlcs() {
    for (const usdtPair of BittrexMarketSummaryService.usdtPairs) {
      const ohlcv = await this.fetchMarketOhlc(usdtPair);
      if (ohlcv) {
        this.marketOhlcvs.set(usdtPair, ohlcv);
      }
    }
  }

  startFetchingInterval(intervalInMilliseconds: number = 15 * 60 * 1000) {
    this.fetchMarketSummaries().then(() => {});
    this.fetchMarketDetailedSummaries().then(() => {});
    this.fetchMarketOhlcs().then(() => {});

    setInterval(() => {
      this.fetchMarketSummaries().then(() => {});
      this.fetchMarketDetailedSummaries().then(() => {});
      this.fetchMarketOhlcs().then(() => {});
    }, intervalInMilliseconds);
  }
}

export default BittrexMarketSummaryService;
