export namespace api {
  //export const BASE_URL = 'http://localhost:8010/vApi';
  export const BASE_URL = "https://app.vtrader.io/vApi";

  export const common = {
    LATEST_BLOG_NEWS: `${BASE_URL}/latest_blog_news`,
    USDT_TICKER: `${BASE_URL}/usdt_ticker`,
    NGN_EUR_RATE: `${BASE_URL}/ngn_eur_rate`,
    FEES: `${BASE_URL}/fees`,
    MIN_TRADE_VALUE_IN_SATOSHI: `${BASE_URL}/min_trade_value_in_satoshi`,
    MARKET_OHLC: `https://api-staging.vtrader.io/helper/ohlc`,
    MARKET_SUMMARIES: `https://api-staging.vtrader.io/helper/market_summaries`,
    MARKET_SUMMARY: `https://api-staging.vtrader.io/helper/market_summary`,
  };
}
