import React from 'react'

export function AskIcon() {
    return (
        <>
            <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26" fill="none">
                <g clipPath="url(#clip0_2_689)">
                    <path d="M12.9845 25.969C20.1556 25.969 25.969 20.1556 25.969 12.9845C25.969 5.81336 20.1556 0 12.9845 0C5.81336 0 0 5.81336 0 12.9845C0 20.1556 5.81336 25.969 12.9845 25.969Z" fill="url(#paint0_linear_2_689)" />
                    <path d="M11.5475 15.0583C11.5204 14.1511 11.8952 13.434 12.5959 12.8745C12.8947 12.6355 13.2152 12.4345 13.5411 12.2389C13.8779 12.038 14.2039 11.8152 14.4918 11.5436C14.9589 11.1036 15.1545 10.5713 14.9752 9.94112C14.8014 9.33271 14.3234 9.03937 13.7367 8.93073C12.9871 8.79492 12.2754 8.87641 11.6507 9.35987C11.2759 9.64778 11.0749 10.0498 10.9228 10.4843C10.7218 11.0439 10.1569 11.3589 9.59735 11.2394C8.90202 11.0873 8.49461 10.4137 8.71733 9.74013C9.19536 8.28973 10.1949 7.36082 11.6562 6.95883C13.1174 6.55685 14.5407 6.66006 15.8335 7.51835C17.2296 8.44726 17.7457 10.1964 17.0721 11.6251C16.7461 12.3096 16.1975 12.793 15.5891 13.2113C15.2197 13.4666 14.8449 13.7056 14.47 13.9555C13.9431 14.3086 13.6606 14.7758 13.6878 15.4385C13.715 16.0578 13.2424 16.5304 12.6557 16.5413C12.0527 16.5576 11.5801 16.1067 11.5475 15.4765C11.5366 15.3462 11.5475 15.2049 11.5475 15.0583Z" fill="url(#paint1_linear_2_689)" />
                    <path d="M12.8291 17.6168C12.9269 17.6277 13.0301 17.644 13.1279 17.6548C13.4647 17.6983 13.6874 17.8993 13.7417 18.2361C13.8015 18.6272 13.8069 19.0183 13.7417 19.404C13.6874 19.73 13.4864 19.9201 13.1659 19.969C12.7748 20.0287 12.3837 20.0287 11.9926 19.969C11.6938 19.9201 11.4928 19.73 11.4385 19.4312C11.3624 19.0564 11.4005 18.6761 11.4059 18.3013C11.5471 17.8124 11.6938 17.6874 12.199 17.644C12.4109 17.6277 12.6227 17.6277 12.8291 17.6168Z" fill="url(#paint2_linear_2_689)" />
                </g>
                <defs>
                    <linearGradient id="paint0_linear_2_689" x1="12.9845" y1="25.969" x2="12.9845" y2="0" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#409B74" />
                        <stop offset="1" stopColor="#70CAA3" stopOpacity="0.62" />
                    </linearGradient>
                    <linearGradient id="paint1_linear_2_689" x1="12.9844" y1="12.0227" x2="13.0109" y2="16.5417" gradientUnits="userSpaceOnUse">
                        <stop stopColor="white" />
                        <stop offset="1" stopColor="white" stopOpacity="0" />
                    </linearGradient>
                    <linearGradient id="paint2_linear_2_689" x1="12.5901" y1="18.2745" x2="12.5901" y2="20.0138" gradientUnits="userSpaceOnUse">
                        <stop stopColor="white" />
                        <stop offset="1" stopColor="white" stopOpacity="0" />
                    </linearGradient>
                    <clipPath id="clip0_2_689">
                        <rect width="25.969" height="25.969" fill="white" />
                    </clipPath>
                </defs>
            </svg>
        </>
    )
}
