class HttpClient {
  async get<T>(
    endpoint: string,
    queryParams?: Record<string, string>,
    authToken?: string | null
  ): Promise<{ status: number; data: T }> {
    try {
      let url = new URL(endpoint);
      if (queryParams) {
        Object.keys(queryParams).forEach((key) => url.searchParams.append(key, queryParams[key]));
      }

      const headers: Record<string, string> = {};
      if (authToken) {
        headers["X-ETH-REST-KEY"] = authToken;
      }

      const response = await fetch(url.toString(), { headers });
      const data = await response.json();
      return { status: response.status, data: data as T };
    } catch (error) {
      return { status: 500, data: {} as T };
    }
  }

  async getBlob(
    endpoint: string,
    queryParams?: Record<string, string>,
    authToken?: string | null
  ): Promise<Blob | null> {
    try {
      let url = new URL(endpoint);
      if (queryParams) {
        Object.keys(queryParams).forEach((key) => url.searchParams.append(key, queryParams[key]));
      }

      const headers: Record<string, string> = {};
      if (authToken) {
        headers["X-ETH-REST-KEY"] = authToken;
      }

      const response = await fetch(url.toString(), { headers });

      if (!response.ok) {
        return null;
      }

      return await response.blob();
    } catch (error) {
      console.error("Error downloading blob:", error);
      return null;
    }
  }

  async post<T, U>(
    endpoint: string,
    model: T,
    authToken?: string
  ): Promise<{ status: number; data: U }> {
    try {
      const headers: Record<string, string> = {
        "Content-Type": "application/json",
      };

      if (authToken) {
        headers["X-ETH-REST-KEY"] = authToken;
      }

      const response = await fetch(endpoint, {
        method: "POST",
        headers,
        body: JSON.stringify(model),
      });

      const data = await response.json();

      return { status: response.status, data: data as U };
    } catch (error) {
      return { status: 500, data: {} as U };
    }
  }

  async postWithFiles<T, U>(
    endpoint: string,
    model: T,
    files: File[],
    authToken?: string
  ): Promise<{ status: number; data: U }> {
    try {
      const formData = new FormData();
      formData.append("model", JSON.stringify(model));

      for (let i = 0; i < files.length; i++) {
        formData.append("files", files[i]);
      }

      const headers: Record<string, string> = {};

      if (authToken) {
        headers["X-ETH-REST-KEY"] = authToken;
      }

      const response = await fetch(endpoint, {
        method: "POST",
        headers,
        body: formData,
      });

      const data = await response.json();

      return { status: response.status, data: data as U };
    } catch (error) {
      return { status: 500, data: {} as U };
    }
  }
}

export default HttpClient;
