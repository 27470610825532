import React, { useEffect, useState } from "react";
import Header from "./components/header/Header";
import Dashboard from "./components/dashboard/Dashboard";
import logoIcon from "./components/header/icons/lg.png";
import BittrexMarketSummaryService from "./services/bittrex-market-summary/BittrexMarketSummaryService";
import { BrowserRouter as Router, Redirect, Route, Switch } from "react-router-dom";
import FeesTickersService from "./services/api/common/fees-tickers-service/FeesTickersService";
import Academy from "./components/academy/Academy";
import Footer from "./components/footer/Footer";
import Currency from "./components/currency/currency";

const AppRouter = () => {
  const [isLoading, setIsLoading] = useState(true); // State to track loading status

  useEffect(() => {
    const initializeApp = async () => {
      const marketSummaryService = BittrexMarketSummaryService.getInstance();
      marketSummaryService.startFetchingInterval();

      const feesTickersService = FeesTickersService.getInstance();
      feesTickersService.fetchFeesAndMinTradeValueInSatoshi();
      feesTickersService.startFetchingInterval();

      setIsLoading(false);
    };

    initializeApp().then(() => {});
  }, []);

  // Render the loading spinner or the application content based on isLoading
  if (isLoading) {
    return (
      <div>
        <div className="loading-container">
          <img className="logo-image" src={logoIcon} alt="Logo" />
          <div style={{ textAlign: "center", paddingTop: "40px" }}>Loading...</div>
          <div className="loading-spinner"></div>
        </div>
      </div>
    );
  }

  return (
    <Router>
      <Header />
      <Switch>
        <Route path="/" component={Dashboard} />
        <Route path="/academy" component={Academy} />
        <Route path="/currencies/:currencyName" exact component={Currency} />
      </Switch>
      <Footer />
    </Router>
  );
};

export default AppRouter;
