export const getCurrencySymbol = (currency: string): string => {
  const currencySymbols: { [key: string]: string } = {
    USDT: "₮",
    ETH: "Ξ",
    BTC: "₿",
    LTC: "Ł",
    ADA: "₳",
    XRP: "Ʀ",
    DOGE: "Ð",
    LINK: "⬡",
    COMP: "⧠",
    AAVE: "Ⓐ",
    UNI: "🦄",
    XLM: "🚀",
    DAI: "⬙",
    GRT: "Ꮆ",
    MKR: "ᛖ",
    UMA: "ᙀ",
    DOT: "○",
    USDC: "$",
    SOL: "◎",
    MATIC: "Ṁ",
    ATOM: "Å",
    ALGO: "Ⱥ",
    AVAX: "Ἅ",
    APE: "Æ",
    EUR: "€",
    NGN: "₦",
  };

  return currencySymbols[currency] || currency;
};
