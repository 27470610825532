import AppRouter from "./Router";
import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";

import TagManager from "react-gtm-module";

const tagManagerArgs = {
  gtmId: "GTM-5L3NC6P9",
};

TagManager.initialize(tagManagerArgs);

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
  <>
    <AppRouter />
  </>
);
