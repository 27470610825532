import CreditCardLogo from "./Credid_Card.svg";
import CurrencyHouse from "./Currency_House.svg";

const currencyData = {
  MKR: {
    coinName: "Maker",
    pageTitle: "Buy Maker with Credit or Debit Card | VTRADER",
    pageDescription: "Buy Maker instantly.",
    whyBuy: `<p class='buy_heading'>We offer unmatched flexibility, security, and simplicity when it comes to purchasing Maker. VTRADER is a premium platform to buy cryptocurrency at the best price, with the lowest fees.</p><p class='buy_heading'>VTRADER offers you many options to buy MKR: <ul><li><img width='32' height='32' className="credit-card" alt="Credit card" src=${CreditCardLogo} /> Buy Maker with a credit card</li><li><img width='32' height='32' className="credit-card" alt="Credit card" src=${CreditCardLogo} /> Buy Maker with a debit card</li><li><img className="credit-card" width='32' height='32' alt="Credit card" src=${CurrencyHouse} /> Buy Maker with a bank transfer</li></ul></p>`,
    howToBuy:
      "<p class='buy_heading'>Check out our step-by-step guide of how to purchase Maker for a smooth and secure transaction.</p><div class='buy_content'><div class='content_list'><span class='count'>1</span><h4>Register on VTRADER</h4><p>It takes under 5 minutes to create an account with us and start purchasing Maker. (It’s 100% free, too!)</p></div><div class='content_list'><span class='count'>2</span><h4>Verify your identity</h4><p>Complete account verification by providing your identity and address documents to secure your transactions.</p></div><div class='content_list'><span class='count'>3</span><h4>Deposit USD/EUR/NGN</h4><p>Top up your VTRADER account by choosing any convenient fiat currency in Dollars, Euros, or Naira and get immediate access to the funds.</p></div><div class='content_list'><span class='count'>4</span><h4>Buy Maker instantly</h4><p>Now you can buy Maker online with a credit card, debit card, or Bank Wire. From $1 to infinity, invest as much as you so desire.</p></div></div>",
    debit:
      "Buy Maker with a debit card instantly using VTRADER. It’s user-friendly, quick, and secure – we have done everything possible to facilitate the best experience.",
    credit:
      "Want to use Visa or Mastercard to make a purchase? Your wish is our command. Simply link your card details and buy Maker with credit card instantly.",
    faq: [
      {
        question: "Why should I buy Maker?",
        answer:
          "Maker is an Ethereum-based governance and utility token of the Maker system. Maker aims to unlock the potential of decentralized finance by building an inclusive platform for economic empowerment that gives everyone equal access to the global financial marketplace.",
      },
      {
        question: "How do I buy Maker with a credit card?",
        answer:
          "You only need to create an account on VTRADER and pass an identity verification. Once completed, you will be able to top up your account using your credit card.",
      },
      {
        question: "How Are New Maker Tokens (MKR) Created?",
        answer:
          "The MKR token is not created through mining. Instead, its value is determined by the demand and supply of Dai tokens - the stablecoin used in the MKR ecosystem. In the event of a collateral auction of the Maker vault, the value of the vault may generate more Dai tokens than required, or fewer Dai tokens to boost liquidity. If the amount of generated Dai tokens falls short, the protocol will mint new MKR tokens to cover the remaining sum for liquidation, thereby increasing the total supply. Conversely, if the amount of Dai tokens generated is more than the required limit, the Maker protocol will burn MKR tokens to decrease the total supply. Cryptocurrency burning is an effective way to reduce availability, create scarcity of existing supply, and increase market value.",
      },
      {
        question: "Is it safe to buy Maker on VTRADER?",
        answer:
          "Your security is our top priority. Upon purchasing any cryptocurrency, we perform identity verification for every user. This allows us to keep you and other visitors safe. In addition, we establish top-notch security measures to ensure your banking and personal data is protected.",
      },
      {
        question: "What can I do with Maker?",
        answer:
          "The possibilities are endless – whether you want to hold, sell, trade, or purchase using MKR. With VTRADER, you don’t have to worry about the safety of your coin. Maker will be safely stored on your account from the moment you make a purchase, and you will be able to access it at any time.",
      },
    ],
  },
  AAVE: {
    pageTitle: "Buy Aave with Credit or Debit Card | VTRADER",
    pageDescription: "Buy Aave instantly.",
    whyBuy: `<p class='buy_heading'>We offer unmatched flexibility, security, and simplicity when it comes to purchasing Aave. VTRADER is a premium platform to buy cryptocurrency at the best price, with the lowest fees.</p><p class='buy_heading'>VTRADER offers you many options to buy AAVE: <ul><li><img width='32' height='32' className="credit-card" alt="Credit card" src=${CreditCardLogo} /> Buy Aave with a credit card</li><li><img width='32' height='32' className="credit-card" alt="Credit card" src=${CreditCardLogo} /> Buy Aave with a debit card</li><li><img className="credit-card" width='32' height='32' alt="Credit card" src=${CurrencyHouse} /> Buy Aave with a bank transfer</li></ul></p>`,
    howToBuy:
      "<p class='buy_heading'>Check out our step-by-step guide of how to purchase Aave for a smooth and secure transaction</p><div class='buy_content'><div class='content_list'><span class='count'>1</span><h4>Register on VTRADER</h4><p>It takes under 5 minutes to create an account with us and start purchasing Aave. (It’s 100% free, too!)</p></div><div class='content_list'><span class='count'>2</span><h4>Verify your identity</h4><p>Complete account verification by providing your identity and address documents to secure your transactions.</p></div><div class='content_list'><span class='count'>3</span><h4>Deposit USD/EUR/NGN</h4><p>Top up your VTRADER account by choosing any convenient fiat currency in Dollars, Euros or Naira and get immediate access to the funds.</p></div><div class='content_list'><span class='count'>3</span><h4>Buy Aave instantly</h4><p>Now you can buy Aave online with a credit card, debit card, or Bank Wire. From $1 to infinity, invest as much as you so desire.</p></div></div>",
    faq: [
      {
        question: "What is Aave?",
        answer:
          "Aave (AAVE) represents a decentralized lending and borrowing system within the realm of decentralized finance (DeFi), operating atop the Ethereum blockchain. It offers an array of innovative features and advantages for participants in the cryptocurrency lending arena.",
      },
      {
        question: "How does Aave work?",
        answer:
          "The possibilities are endless – whether you want to hold, sell, trade, or purchase using AAVE. With VTRADER, you don’t have to worry about the safety of your coin. Maker will be safely stored on your account from the moment you make a purchase, and you will be able to access it at any time.",
      },
      {
        question: "How do I buy AAVE with credit card?",
        answer:
          "You only need to create an account on VTRADER and pass an identity verification. Once completed, you will be able to top up your account using your credit card.",
      },
      {
        question: "Is it safe to buy Aave on VTRADER?",
        answer:
          "Your security is our top priority. Upon purchasing any cryptocurrency, we perform identity verification for every user. This allows us to keep you and other visitors safe. In addition, we establish top-notch security measures to ensure your banking and personal data is protected.",
      },
    ],
    debit:
      "Buy Aave with a debit card instantly using VTRADER. It’s user-friendly, quick, and secure – we have done everything possible to facilitate the best experience.",
    coinName: "AAVE",
    credit:
      "Want to use Visa or Mastercard to make a purchase? Your wish is our command. Simply link your card details and buy Aave with credit card instantly",
  },
  APE: {
    whyBuy: `<p class='buy_heading'>We offer unmatched flexibility, security, and simplicity when it comes to purchasing ApeCoin. VTRADER is a premium platform to buy cryptocurrency at the best price, with the lowest fees.</p><p class='buy_heading'>VTRADER offers you many options to buy APE: <ul><li><img width='32' height='32' className="credit-card" alt="Credit card" src=${CreditCardLogo} /> Buy ApeCoin with a credit card</li><li><img width='32' height='32' className="credit-card" alt="Credit card" src=${CreditCardLogo} /> Buy ApeCoin with a debit card</li><li><img className="credit-card" width='32' height='32' alt="Credit card" src=${CurrencyHouse} /> Buy ApeCoin with a bank transfer</li></ul></p>`,
    howToBuy:
      "<p class='buy_heading'>Check out our step-by-step guide of how to purchase ApeCoin for a smooth and secure transaction.</p><div class='buy_content'><div class='content_list'><span class='count'>1</span><h4>Register on VTRADER</h4><p>It takes under 5 minutes to create an account with us and start purchasing ApeCoin. (It’s 100% free, too!)</p></div><div class='content_list'><span class='count'>2</span><h4>Verify your identity</h4><p>Complete account verification by providing your identity and address documents to secure your transactions.</p></div><div class='content_list'><span class='count'>3</span><h4>Deposit USD/EUR/NGN</h4><p>Top up your VTRADER account by choosing any convenient fiat currency and get immediate access to the funds.</p></div><div class='content_list'><span class='count'>4</span><h4>Buy ApeCoin instantly</h4><p>Now you can buy ApeCoin online with a credit card, debit card, or Bank Wire. From $1 to infinity, invest as much as you so desire.</p></div></div>",
    faq: [
      {
        question: "What is ApeCoin?",
        answer:
          "ApeCoin (APE) functions as both a utility and governance token, designed to empower the community associated with the APE ecosystem. This ecosystem has its roots in the Bored Ape Yacht Club (BAYC). The Bored Ape Yacht Club gained significant popularity during the NFT boom of 2021, featuring a collection of 10,000 unique cartoon ape images. Notably, rare Apes from this collection were sold for substantial amounts, reaching hundreds of millions of dollars. ApeCoin appears to have been developed to enhance the APE ecosystem, inspired by the success of the Bored Ape Yacht Club. Utility tokens like ApeCoin typically play a role in governance and various practical functions within the associated blockchain ecosystem, enabling token holders to participate in decision-making processes and facilitating transactions or accessing exclusive features.",
      },
      {
        question: "How do I buy APE with credit card?",
        answer:
          "You only need to create an account on VTRADER and pass an identity verification. Once completed, you will be able to top up your account using your credit card.",
      },
      {
        question: "Is it safe to buy APE on VTRADER?",
        answer:
          "Your security is our top priority. Upon purchasing any cryptocurrency, we perform identity verification for every user. This allows us to keep you and other visitors safe. In addition, we establish top-notch security measures to ensure your banking and personal data is protected.",
      },
      {
        question: "What can I do with ApeCoin?",
        answer:
          "The possibilities are endless – whether you want to hold, sell, trade, or purchase using APE. With VTRADER, you don’t have to worry about the safety of your coin. ApeCoin will be safely stored on your account from the moment you make a purchase, and you will be able to access it at any time.",
      },
    ],
    debit:
      "Buy ApeCoin with a debit card instantly using VTRADER. It’s user-friendly, quick, and secure – we have done everything possible to facilitate the best experience.",
    credit:
      "Want to use Visa or Mastercard to make a purchase? Your wish is our command. Simply link your card details and buy ApeCoin with credit card instantly.",
    pageTitle: "Buy ApeCoin with Credit or Debit Card| VTRADER",
    pageDescription: "Buy ApeCoin instantly",
    coinName: "ApeCoin",
  },
  AVAX: {
    fastBuyTitle: "Buy Avalanche with credit card in the fastest way",
    whyBuyTitle: "Why credit cards are the best way to buy AVAX",
    buyFastesWay:
      "Type in the amount of AVAX you wish to buy in the dashboard above. To buy Avalanche with a credit card, select the payment method from the available options. Then, start the transaction and follow the indicated steps. If this is your first transaction, you will be asked to verify your identity and enter the details of your card. You can then store the card’s details to buy Avalanche with a credit card instantly.",
    whyBuy:
      "Apart from allowing you to buy Avalanche instantly, credit cards can also be used strategically to benefit from the volatile conditions of the crypto market. More specifically, you can deploy borrowed capital when the price fluctuates to temporary lows and profit from the subsequent market recovery. <br><br> Credit cards also enable you to log all the times you buy Avalanche in order to better track your tax obligations. All the information can be exported into a report for your accountant.",
    chooseToBuyTitle: "Choosing to buy Avalanche with a debit card instead",
    chooseToBuy:
      "If you’d rather not accumulate debt, then debit cards may be a better option. VTRADER allows you to buy Avalanche with a debit card (Visa and Mastercard) by going through the exact same process. Head back to the order panel, indicate the desired amount, and buy AVAX with a debit card as your payment method. Similar to credit cards, once your account is verified, you’ll be able to buy Avalanche with a debit card instantly every following time.",
    howToBuyTitle: "How to buy Avalanche with alternative methods",
    howToBuy:
      "At the moment, our payment methods extend to card payments and bank transfers. In the coming months, we intend to add more payment methods so you can buy AVAX in the most convenient way. Learning how to purchase Avalanche with a bank payment is a self-intuitive process. Simply scroll up to the transaction dashboard, indicate the payment method, and make an order.<br></br>",
    faq: [
      {
        question: "Why should I buy Avalanche?",
        answer:
          "Avalanche, a cryptocurrency that has gained prominence in the digital currency domain, stands out due to its distinctive algorithm and swift transaction speed, positioning itself among the most popular digital currencies. The curiosity surrounding Avalanche's future persists, with many wondering whether its value will continue its upward trajectory. The unique consensus algorithm and scalability features are pivotal factors contributing to its appeal. The prospects for Avalanche hinge on various elements, including technological advancements, adoption rates, regulatory changes, market trends, and its competitive standing. Community engagement, strategic partnerships, and the project's ability to navigate security challenges will also play crucial roles in determining its future success. Given the dynamic nature of the cryptocurrency landscape, staying informed about the latest developments and conducting thorough research is essential for those interested in the potential of Avalanche.",
      },
      {
        question: "How do I buy Avalanche with a credit card?",
        answer:
          "To understand how to buy Avalanche, check the list of steps posted above and choose a credit card as your payment method. It is important to remember that before you are able to buy Avalanche online with a credit card, you will need to verify your account by performing a quick KYC check.",
      },
      {
        question: "How much Avalanche can I buy?",
        answer:
          "The amount of AVAX you can buy depends on the verification stage of your account. To better understand the tiers and limits of your profile, make sure you check this page.",
      },
      {
        question: "Is it safe to buy Avalanche on VTRADER?",
        answer:
          "Hesitation is normal when you decide to make your first purchase. Whether you buy AVAX with a credit card or any of the alternative methods, know that our platform abides by regulatory frameworks and is PCI-DSS compliant, and we implement the highest level of security for all transactions.",
      },
      {
        question: "What can I do with Avalanche?",
        answer:
          "Avalanche is mainly used as a long-term investment. Its current value is only a fraction of its all-time high, which makes many investors hopeful for a large price appreciation in the future. The coin can also be used for trading purposes, but it is important to consider the associated risks.",
      },
      {
        question: "What can I buy with Avalanche?",
        answer:
          "The possibilities are endless – whether you want to hold, sell, trade, or purchase using AVAX. With VTRADER, you don’t have to worry about the safety of your coin. Avalanche will be safely stored on your account from the moment you make a purchase, and you will be able to access it at any time.",
      },
    ],
    pageTitle: "Buy Avalanche with Credit or Debit Card| VTRADER",
    pageDescription: "Buy Avalanche instantly",
    coinName: "Avalanche",
  },
  ALGO: {
    whyBuy: `<p class='buy_heading'>We offer unmatched flexibility, security, and simplicity when it comes to purchasing Algorand. VTRADER is a premium platform to buy cryptocurrency at the best price, with the lowest fees.</p><p class='buy_heading'>VTRADER offers you many options to buy ALGO: <ul><li><img width='32' height='32' className="credit-card" alt="Credit card" src=${CreditCardLogo} /> Buy Algorand with a credit card</li><li><img width='32' height='32' className="credit-card" alt="Credit card" src=${CreditCardLogo} /> Buy Algorand with a debit card</li><li><img className="credit-card" width='32' height='32' alt="Credit card" src=${CurrencyHouse} /> Buy Algorand with a bank transfer</li></ul></p>`,
    howToBuy:
      "<p class='buy_heading'>Check out our step-by-step guide of how to purchase Algorand for a smooth and secure transaction.</p><div class='buy_content'><div class='content_list'><span class='count'>1</span><h4>Register on VTRADER</h4><p>It takes under 5 minutes to create an account with us and start purchasing Algorand. (It’s 100% free, too!)</p></div><div class='content_list'><span class='count'>2</span><h4>Verify your identity</h4><p>Complete account verification by providing your identity and address documents to secure your transactions.</p></div><div class='content_list'><span class='count'>3</span><h4>Deposit USD/EUR/NGN</h4><p>Top up your VTRADER account by choosing any convenient fiat currency in Dollars, Euros, or Naira and get immediate access to the funds.</p></div><div class='content_list'><span class='count'>4</span><h4>Buy Algorand instantly</h4><p>Now you can buy Algorand online with a credit card, debit card, or Bank Wire. From $1 to infinity, invest as much as you so desire.</p></div></div>",
    debit:
      "Buy Algorand with a debit card instantly using VTRADER. It’s user-friendly, quick, and secure – we have done everything possible to facilitate the best experience.",
    credit:
      "Want to use Visa or Mastercard to make a purchase? Your wish is our command. Simply link your card details and buy Algorand with credit card instantly.",
    faq: [
      {
        question: "Why should I buy Algorand?",
        answer:
          "Investors might consider buying Algorand (ALGO) for several reasons. It boasts scalability, using a pure proof-of-stake consensus mechanism for efficiency. Algorand supports smart contracts, allowing decentralized application development. With a strong team led by Turing Award-winning cryptographer Silvio Micali, regular updates, and a focus on security, Algorand aims to provide a secure and dynamic platform. Partnerships and regulatory compliance further contribute to its potential. However, like any investment, risks exist, and thorough research, considering risk tolerance, is advisable before purchasing ALGO or any other cryptocurrency.",
      },
      {
        question: "How do I buy Algorand with a credit card?",
        answer:
          "You only need to create an account on VTRADER and pass an identity verification. Once completed, you will be able to top up your account using your credit card.",
      },
      {
        question: "How Are New Algorand Tokens (ALGO) Created?",
        answer:
          "New Algorand (ALGO) tokens are created through a proof-of-stake (PoS) mechanism known as pure proof-of-stake (PPoS). Users stake their ALGO tokens as collateral, and a randomly selected committee proposes and validates new blocks. Participants in this process receive newly created ALGO tokens as rewards, encouraging active participation in securing the network. The total token supply increases over time through this staking and consensus mechanism.",
      },
      {
        question: "Is it safe to buy Algorand on VTRADER?",
        answer:
          "Your security is our top priority. Upon purchasing any cryptocurrency, we perform identity verification for every user. This allows us to keep you and other visitors safe. In addition, we establish top-notch security measures to ensure your banking and personal data is protected.",
      },
      {
        question: "What can I do with Algorand?",
        answer:
          "The possibilities are endless – whether you want to hold, sell, trade, or purchase using ALGO. With VTRADER, you don’t have to worry about the safety of your coin. Algorand will be safely stored on your account from the moment you make a purchase, and you will be able to access it at any time.",
      },
    ],
    pageTitle: "Buy Algorand with Credit or Debit Card| VTRADER",
    pageDescription: "Buy Algorand instantly",
    coinName: "Algorand",
  },
  COMP: {
    coinName: "Compound",
    pageTitle: "Buy Compound with Credit or Debit Card | VTRADER",
    pageDescription: "Buy Compound instantly",
    whyBuy: `<p class='buy_heading'>We offer unmatched flexibility, security, and simplicity when it comes to purchasing Compound. VTRADER is a premium platform to buy cryptocurrency at the best price, with the lowest fees.</p><p class='buy_heading'>VTRADER offers you many options to buy COMP: <ul><li><img width='32' height='32' className="credit-card" alt="Credit card" src=${CreditCardLogo} /> Buy Compound with a credit card</li><li><img width='32' height='32' className="credit-card" alt="Credit card" src=${CreditCardLogo} /> Buy Compound with a debit card</li><li><img className="credit-card" width='32' height='32' alt="Credit card" src=${CurrencyHouse} /> Buy Compound with a bank transfer</li></ul></p>`,
    howToBuy:
      "<p class='buy_heading'>Check out our step-by-step guide of how to purchase Compound for a smooth and secure transaction.</p><div class='buy_content'><div class='content_list'><span class='count'>1</span><h4>Register on VTRADER</h4><p>It takes under 5 minutes to create an account with us and start purchasing Compound. (It’s 100% free, too!)</p></div><div class='content_list'><span class='count'>2</span><h4>Verify your identity</h4><p>Complete account verification by providing your identity and address documents to secure your transactions.</p></div><div class='content_list'><span class='count'>3</span><h4>Deposit USD/EUR/NGN</h4><p>Top up your VTRADER account by choosing any convenient fiat currency and get immediate access to the funds.</p></div><div class='content_list'><span class='count'>4</span><h4>Buy Compound instantly</h4><p>Now you can buy Compound online with a credit card, debit card, or Bank Wire. From $1 to infinity, invest as much as you so desire.</p></div></div>",
    credit:
      "Want to use Visa or Mastercard to make a purchase? Your wish is our command. Simply link your card details and buy Compound with a credit card instantly.",
    debit:
      "Buy Compound with a debit card instantly using VTRADER. It’s user-friendly, quick, and secure – we have done everything possible to facilitate your best experience.",
    faq: [
      {
        question: "Why should I buy Compound?",
        answer:
          "The governance token for Compound, known as COMP, operates on the Ethereum blockchain. This token is linked to the Compound protocol, a decentralized finance (DeFi) platform established on the Ethereum blockchain. Through the use of smart contracts, the protocol facilitates the lending and borrowing of a diverse range of ERC-20 tokens.",
      },
      {
        question: "How do I buy Compound with a credit card?",
        answer:
          "You only need to create an account on VTRADER and pass an identity verification. Once completed, you will be able to top up your account using your credit card.",
      },
      {
        question: "How much Compound can I buy?",
        answer:
          "VTRADER is a flexible platform that lets you decide how much COMP you want to buy. Begin with $1 and go as high as you want, depending on your Tier (please consult our Terms & Conditions, or contact our Customer Support for more information).",
      },
      {
        question: "Is it safe to buy Compound on VTRADER?",
        answer:
          "Your security is our top priority. Upon purchasing any cryptocurrency, we perform identity verification for every user. This allows us to keep you and other visitors safe. In addition, we establish top-notch security measures to ensure your banking and personal data is protected.",
      },
      {
        question: "What can I do with Compound?",
        answer:
          "The possibilities are endless – whether you want to hold, sell, trade, or purchase using COMP. With VTRADER, you don’t have to worry about the safety of your coin. Compound will be safely stored on your account from the moment you make a purchase, and you will be able to access it at any time.",
      },
    ],
  },
  DAI: {
    coinName: "DAI",
    pageTitle: "Buy Dai with Credit or Debit Card | VTRADER",
    pageDescription: "Buy Dai instantly",
    whyBuy: `<p class='buy_heading'>We offer unmatched flexibility, security, and simplicity when it comes to purchasing Dai. VTRADER is a premium platform to buy cryptocurrency at the best price, with the lowest fees.</p><p class='buy_heading'>VTRADER offers you many options to buy DAI: <ul><li><img width='32' height='32' className="credit-card" alt="Credit card" src=${CreditCardLogo} /> Buy Dai with a credit card</li><li><img width='32' height='32' className="credit-card" alt="Credit card" src=${CreditCardLogo} /> Buy Dai with a debit card</li><li><img className="credit-card" width='32' height='32' alt="Credit card" src=${CurrencyHouse} /> Buy Dai with a bank transfer</li></ul></p>`,
    howToBuy:
      "<p class='buy_heading'>Check out our step-by-step guide of how to purchase Dai for a smooth and secure transaction.</p><div class='buy_content'><div class='content_list'><span class='count'>1</span><h4>Register on VTRADER</h4><p>It takes under 5 minutes to create an account with us and start purchasing Dai. (It’s 100% free, too!)</p></div><div class='content_list'><span class='count'>2</span><h4>Verify your identity</h4><p>Complete account verification by providing your identity and address documents to secure your transactions.</p></div><div class='content_list'><span class='count'>3</span><h4>Deposit USD/EUR/NGN</h4><p>Top up your VTRADER account by choosing any convenient fiat currency and get immediate access to the funds.</p></div><div class='content_list'><span class='count'>4</span><h4>Buy Dai instantly</h4><p>Now you can buy Dai online with a credit card, debit card, or Bank Wire. From $1 to infinity, invest as much as you so desire.</p></div></div>",
    credit:
      "Want to use Visa or Mastercard to make a purchase? Your wish is our command. Simply link your card details and buy Dai with a credit card instantly.",
    debit:
      "Buy Dai with a debit card instantly using VTRADER. It’s user-friendly, quick, and secure – we have done everything possible to facilitate your best experience.",
    faq: [
      {
        question: "Why is Dai?",
        answer:
          "Dai (DAI) is a decentralized stablecoin operating on the Ethereum (ETH) blockchain, aiming to sustain a value of $1.00 USD. Unlike centralized stablecoins, Dai doesn't rely on US dollars held in a bank account. Instead, its value is supported by collateral on the Maker platform.",
      },
      {
        question: "How do I buy Dai with a credit card?",
        answer:
          "You only need to create an account on VTRADER and pass an identity verification. Once completed, you will be able to top up your account using your credit card.",
      },
      {
        question: "How much Dai can I buy?",
        answer:
          "VTRADER is a flexible platform that lets you decide how much DAI you want to buy. Begin with $1 and go as high as you want, depending on your Tier (please consult our Terms & Conditions, or contact our Customer Support for more information).",
      },
      {
        question: "Is it safe to buy Dai on VTRADER?",
        answer:
          "Your security is our top priority. Upon purchasing any cryptocurrency, we perform identity verification for every user. This allows us to keep you and other visitors safe. In addition, we establish top-notch security measures to ensure your banking and personal data is protected.",
      },
      {
        question: "What can I do with Dai?",
        answer:
          "The possibilities are endless – whether you want to hold, sell, trade, or purchase using DAI. With VTRADER, you don’t have to worry about the safety of your coin. Dai will be safely stored on your account from the moment you make a purchase, and you will be able to access it at any time.",
      },
    ],
  },
  DOT: {
    coinName: "Polkadot",
    pageTitle: "Buy Polkadot with Credit or Debit Card | VTRADER",
    pageDescription: "Buy Polkadot instantly.",
    whyBuy: `<p class='buy_heading'>We offer unmatched flexibility, security, and simplicity when it comes to purchasing Polkadot. VTRADER is a premium platform to buy cryptocurrency at the best price, with the lowest fees.</p><p class='buy_heading'>VTRADER offers you many options to buy DOT: <ul><li><img width='32' height='32' className="credit-card" alt="Credit card" src=${CreditCardLogo} /> Buy Polkadot with a credit card</li><li><img width='32' height='32' className="credit-card" alt="Credit card" src=${CreditCardLogo} /> Buy Polkadot with a debit card</li><li><img className="credit-card" width='32' height='32' alt="Credit card" src=${CurrencyHouse} /> Buy Polkadot with a bank transfer</li></ul></p>`,
    howToBuy:
      "<p class='buy_heading'>Check out our step-by-step guide of how to purchase Polkadot for a smooth and secure transaction.</p><div class='buy_content'><div class='content_list'><span class='count'>1</span><h4>Register on VTRADER</h4><p>It takes under 5 minutes to create an account with us and start purchasing Polkadot. (It’s 100% free, too!)</p></div><div class='content_list'><span class='count'>2</span><h4>Verify your identity</h4><p>Complete account verification by providing your identity and address documents to secure your transactions.</p></div><div class='content_list'><span class='count'>3</span><h4>Deposit USD/EUR/NGN</h4><p>Top up your VTRADER account by choosing any convenient fiat currency and get immediate access to the funds.</p></div><div class='content_list'><span class='count'>4</span><h4>Buy Polkadot instantly</h4><p>Now you can buy Polkadot online with a credit card, debit card, or Bank Wire. From $1 to infinity, invest as much as you so desire.</p></div></div>",
    credit:
      "Want to use Visa or Mastercard to make a purchase? Your wish is our command. Simply link your card details and buy Polkadot with a credit card instantly.",
    debit:
      "Buy Polkadot with a debit card instantly using VTRADER. It’s user-friendly, quick, and secure – we have done everything possible to facilitate your best experience.",
    faq: [
      {
        question: "What is Polkadot?",
        answer:
          "Polkadot is a protocol that enables cross-blockchain transfers of any type of data or asset. By uniting multiple blockchains, Polkadot aims to achieve high degrees of security and scalability. DOT serves as the protocol’s governance token and can be used for staking to secure the network or to connect new chains.",
      },
      {
        question: "How do I buy Polkadot with a credit card?",
        answer:
          "You only need to create an account on VTRADER and pass an identity verification. Once completed, you will be able to top up your account using your credit card.",
      },
      {
        question: "How much Polkadot can I buy?",
        answer:
          "VTRADER is a flexible platform that lets you decide how much DOT you want to buy. Begin with $1 and go as high as you want, depending on your Tier (please consult our Terms & Conditions, or contact our Customer Support for more information).",
      },
      {
        question: "Is it safe to buy Polkadot on VTRADER?",
        answer:
          "Your security is our top priority. Upon purchasing any cryptocurrency, we perform identity verification for every user. This allows us to keep you and other visitors safe. In addition, we establish top-notch security measures to ensure your banking and personal DOT is protected.",
      },
      {
        question: "What can I do with Polkadot?",
        answer:
          "The possibilities are endless – whether you want to hold, sell, trade, or purchase using DOT. With VTRADER, you don’t have to worry about the safety of your coin. Polkadot will be safely stored on your account from the moment you make a purchase, and you will be able to access it at any time.",
      },
    ],
  },
  GRT: {
    coinName: "Graph",
    pageTitle: "Buy Graph with Credit or Debit Card | VTRADER",
    pageDescription: "Buy Graph instantly.",
    whyBuy: `<p class='buy_heading'>We offer unmatched flexibility, security, and simplicity when it comes to purchasing Graph. VTRADER is a premium platform to buy cryptocurrency at the best price, with the lowest fees.</p><p class='buy_heading'>VTRADER offers you many options to buy GRT: <ul><li><img width='32' height='32' className="credit-card" alt="Credit card" src=${CreditCardLogo} /> Buy Graph with a credit card</li><li><img width='32' height='32' className="credit-card" alt="Credit card" src=${CreditCardLogo} /> Buy Graph with a debit card</li><li><img className="credit-card" width='32' height='32' alt="Credit card" src=${CurrencyHouse} /> Buy Graph with a bank transfer</li></ul></p>`,
    howToBuy:
      "<p class='buy_heading'>Check out our step-by-step guide of how to purchase Graph for a smooth and secure transaction.</p><div class='buy_content'><div class='content_list'><span class='count'>1</span><h4>Register on VTRADER</h4><p>It takes under 5 minutes to create an account with us and start purchasing Graph. (It’s 100% free, too!)</p></div><div class='content_list'><span class='count'>2</span><h4>Verify your identity</h4><p>Complete account verification by providing your identity and address documents to secure your transactions.</p></div><div class='content_list'><span class='count'>3</span><h4>Deposit USD/EUR/NGN</h4><p>Top up your VTRADER account by choosing any convenient fiat currency and get immediate access to the funds.</p></div><div class='content_list'><span class='count'>4</span><h4>Buy Graph instantly</h4><p>Now you can buy Graph online with a credit card, debit card, or Bank Wire. From $1 to infinity, invest as much as you so desire.</p></div></div>",
    credit:
      "Want to use Visa or Mastercard to make a purchase? Your wish is our command. Simply link your card details and buy Graph with a credit card instantly.",
    debit:
      "Buy Graph with a debit card instantly using VTRADER. It’s user-friendly, quick, and secure – we have done everything possible to facilitate your best experience.",
    faq: [
      {
        question: "What is Graph?",
        answer:
          "GRT, an Ethereum-based token, plays a pivotal role in supporting The Graph, a decentralized protocol designed for the indexing and querying of data sourced from various blockchains. Drawing an analogy to Google's indexing of the web, The Graph performs a similar function but specifically focuses on indexing blockchain data from networks such as Ethereum and Filecoin. The data is organized into accessible open APIs known as subgraphs, which are available for querying by anyone interested in gaining insights from the indexed blockchain data. This decentralized approach to data indexing and querying underscores the versatility and transparency inherent in The Graph's infrastructure, providing an open and decentralized resource for accessing blockchain information.",
      },
      {
        question: "How do I buy Graph with a credit card?",
        answer:
          "You only need to create an account on VTRADER and pass an identity verification. Once completed, you will be able to top up your account using your credit card.",
      },
      {
        question: "How much Graph can I buy?",
        answer:
          "VTRADER is a flexible platform that lets you decide how much GRT you want to buy. Begin with $1 and go as high as you want, depending on your Tier (please consult our Terms & Conditions, or contact our Customer Support for more information).",
      },
      {
        question: "Is it safe to buy Graph on VTRADER?",
        answer:
          "Your security is our top priority. Upon purchasing any cryptocurrency, we perform identity verification for every user. This allows us to keep you and other visitors safe. In addition, we establish top-notch security measures to ensure your banking and personal Graph is protected.",
      },
      {
        question: "What can I do with Graph?",
        answer:
          "The possibilities are endless – whether you want to hold, sell, trade, or purchase using GRT. With VTRADER, you don’t have to worry about the safety of your coin. Graph will be safely stored on your account from the moment you make a purchase, and you will be able to access it at any time.",
      },
    ],
  },
  UMA: {
    coinName: "UMA",
    pageTitle: "Buy UMA with Credit or Debit Card | VTRADER",
    pageDescription: "Buy UMA instantly.",
    whyBuy: `<p class='buy_heading'>We offer unmatched flexibility, security, and simplicity when it comes to purchasing UMA. VTRADER is a premium platform to buy cryptocurrency at the best price, with the lowest fees.</p><p class='buy_heading'>VTRADER offers you many options to buy UMA: <ul><li><img width='32' height='32' className="credit-card" alt="Credit card" src=${CreditCardLogo} />Buy UMA with a credit card</li><li><img width='32' height='32' className="credit-card" alt="Credit card" src=${CreditCardLogo} />Buy UMA with a debit card</li><li><img className="credit-card" width='32' height='32' alt="Credit card" src=${CurrencyHouse} />Buy UMA with a bank transfer</li></ul></p>`,
    howToBuy:
      "<p class='buy_heading'>Check out our step-by-step guide of how to purchase UMA for a smooth and secure transaction.</p><div class='buy_content'><div class='content_list'><span class='count'>1</span><h4>Register on VTRADER</h4><p>It takes under 5 minutes to create an account with us and start purchasing UMA. (It’s 100% free, too!)</p></div><div class='content_list'><span class='count'>2</span><h4>Verify your identity</h4><p>Complete account verification by providing your identity and address documents to secure your transactions.</p></div><div class='content_list'><span class='count'>3</span><h4>Deposit USD/EUR/NGN</h4><p>Top up your VTRADER account by choosing any convenient fiat currency and get immediate access to the funds.</p></div><div class='content_list'><span class='count'>4</span><h4>Buy UMA instantly</h4><p>Now you can buy UMA online with a credit card, debit card, or Bank Wire. From $1 to infinity, invest as much as you so desire.</p></div></div>",
    credit:
      "Want to use Visa or Mastercard to make a purchase? Your wish is our command. Simply link your card details and buy UMA with a credit card instantly.",
    debit:
      "Buy UMA with a debit card instantly using VTRADER. It’s user-friendly, quick, and secure – we have done everything possible to facilitate your best experience.",
    faq: [
      {
        question: "What is UMA?",
        answer:
          "UMA is an Ethereum token powering a groundbreaking open-source protocol, enabling developers to craft their financial contracts and synthetic assets. The name UMA reflects the platform's mission of achieving universal market access. Within this dynamic ecosystem, developers leverage Ethereum's smart contract capabilities to design a diverse range of financial products. The open-source nature encourages collaboration and transparency, fostering innovation in decentralized finance (DeFi). UMA's commitment to universal market access aligns with the decentralized ethos, breaking down traditional barriers to create a more inclusive financial landscape. In essence, UMA is not just a protocol but a hub for creative exploration, collaboration, and innovation in decentralized finance.",
      },
      {
        question: "How do I buy UMA with a credit card?",
        answer:
          "You only need to create an account on VTRADER and pass an identity verification. Once completed, you will be able to top up your account using your credit card.",
      },
      {
        question: "How much UMA can I buy?",
        answer:
          "VTRADER is a flexible platform that lets you decide how much UMA you want to buy. Begin with $1 and go as high as you want, depending on your Tier (please consult our Terms & Conditions, or contact our Customer Support for more information).",
      },
      {
        question: "Is it safe to buy UMA on VTRADER?",
        answer:
          "Your security is our top priority. Upon purchasing any cryptocurrency, we perform identity verification for every user. This allows us to keep you and other visitors safe. In addition, we establish top-notch security measures to ensure your banking and personal UMA is protected.",
      },
      {
        question: "What can I do with UMA?",
        answer:
          "The possibilities are endless – whether you want to hold, sell, trade, or purchase using UMA. With VTRADER, you don’t have to worry about the safety of your coin. UMA will be safely stored on your account from the moment you make a purchase, and you will be able to access it at any time.",
      },
    ],
  },
  UNI: {
    coinName: "Uniswap",
    pageTitle: "Buy Uniswap with Credit or Debit Card | VTRADER",
    pageDescription: "Buy Uniswap instantly.",
    whyBuy: `<p class='buy_heading'>We offer unmatched flexibility, security, and simplicity when it comes to purchasing Uniswap. VTRADER is a premium platform to buy cryptocurrency at the best price, with the lowest fees.</p><p class='buy_heading'>VTRADER offers you many options to buy UNI: <ul><li><img width='32' height='32' className="credit-card" alt="Credit card" src=${CreditCardLogo} />Buy Uniswap with a credit card</li><li><img width='32' height='32' className="credit-card" alt="Credit card" src=${CreditCardLogo} />Buy Uniswap with a debit card</li><li><img className="credit-card" width='32' height='32' alt="Credit card" src=${CurrencyHouse} />Buy Uniswap with a bank transfer</li></ul></p>`,
    howToBuy:
      "<p class='buy_heading'>Check out our step-by-step guide of how to purchase Uniswap for a smooth and secure transaction.</p><div class='buy_content'><div class='content_list'><span class='count'>1</span><h4>Register on VTRADER</h4><p>It takes under 5 minutes to create an account with us and start purchasing Uniswap. (It’s 100% free, too!)</p></div><div class='content_list'><span class='count'>2</span><h4>Verify your identity</h4><p>Complete account verification by providing your identity and address documents to secure your transactions.</p></div><div class='content_list'><span class='count'>3</span><h4>Deposit USD/EUR/NGN</h4><p>Top up your VTRADER account by choosing any convenient fiat currency and get immediate access to the funds.</p></div><div class='content_list'><span class='count'>4</span><h4>Buy Uniswap instantly</h4><p>Now you can buy Uniswap online with a credit card, debit card, or Bank Wire. From $1 to infinity, invest as much as you so desire.</p></div></div>",
    credit:
      "Want to use Visa or Mastercard to make a purchase? Your wish is our command. Simply link your card details and buy Uniswap with a credit card instantly.",
    debit:
      "Buy Uniswap with a debit card instantly using VTRADER. It’s user-friendly, quick, and secure – we have done everything possible to facilitate your best experience.",
    faq: [
      {
        question: "What is Uniswap?",
        answer:
          "Uniswap (UNI) is the Ethereum token driving Uniswap, an automated liquidity provider designed for effortless Ethereum (ERC-20) token exchanges. Uniswap stands out for its decentralized approach, discarding traditional order books and central facilitators. Instead, it relies on smart contracts to define and manage liquidity pools, where users contribute pairs of tokens for decentralized exchange. This model simplifies user experiences, promotes efficiency, and aligns with the decentralized finance (DeFi) ethos, allowing trustless and open access to token trading directly from wallets.",
      },
      {
        question: "How do I buy Uniswap with a credit card?",
        answer:
          "You only need to create an account on VTRADER and pass an identity verification. Once completed, you will be able to top up your account using your credit card.",
      },
      {
        question: "How much Uniswap can I buy?",
        answer:
          "VTRADER is a flexible platform that lets you decide how much UNI you want to buy. Begin with $1 and go as high as you want, depending on your Tier (please consult our Terms & Conditions, or contact our Customer Support for more information).",
      },
      {
        question: "Is it safe to buy Uniswap on VTRADER?",
        answer:
          "Your security is our top priority. Upon purchasing any cryptocurrency, we perform identity verification for every user. This allows us to keep you and other visitors safe. In addition, we establish top-notch security measures to ensure your banking and personal UNI is protected.",
      },
      {
        question: "What can I do with Uniswap?",
        answer:
          "The possibilities are endless – whether you want to hold, sell, trade, or purchase using UNI. With VTRADER, you don’t have to worry about the safety of your coin. Uniswap will be safely stored on your account from the moment you make a purchase, and you will be able to access it at any time.",
      },
    ],
  },
  USDC: {
    coinName: "USDC",
    pageTitle: "Buy USDC with Credit or Debit Card | VTRADER",
    pageDescription: "Buy USDC instantly.",
    whyBuy: `<p class='buy_heading'>We offer unmatched flexibility, security, and simplicity when it comes to purchasing USDC. VTRADER is a premium platform to buy cryptocurrency at the best price, with the lowest fees.</p><p class='buy_heading'>VTRADER offers you many options to buy USDC:</p> <ul><li><img width='32' height='32' className="credit-card" alt="Credit card" src=${CreditCardLogo} />Buy USDC with a credit card</li><li><img width='32' height='32' className="credit-card" alt="Credit card" src=${CreditCardLogo} />Buy USDC with a debit card</li><li><img className="credit-card" width='32' height='32' alt="Credit card" src=${CurrencyHouse} />Buy USDC with a bank transfer</li></ul>`,
    howToBuy:
      "<p class='buy_heading'>Check out our step-by-step guide of how to purchase <br> USDC for a smooth and secure transaction.</p><div class='buy_content'><div class='content_list'><span class='count'>1</span><h4>Register on VTRADER</h4><p>It takes under 5 minutes to create an account with us and start purchasing USDC. (It’s 100% free, too!)</p></div><div class='content_list'><span class='count'>2</span><h4>Verify your identity</h4><p>Complete account verification by providing your identity and address documents to secure your transactions.</p></div><div class='content_list'><span class='count'>3</span><h4>Deposit USD/EUR/NGN</h4><p>Top up your VTRADER account by choosing any convenient fiat currency and get immediate access to the funds.</p></div><div class='content_list'><span class='count'>4</span><h4>Buy USDC instantly</h4><p>Now you can buy USDC online with a credit card, debit card, or Bank Wire. From $1 to infinity, invest as much as you so desire.</p></div></div>",
    credit:
      "Want to use Visa or Mastercard to make a purchase? Your wish is our command. Simply link your card details and buy Uniswap with a credit card instantly.",
    debit:
      "Buy USDC with a debit card instantly using VTRADER. It’s user-friendly, quick, and secure – we have done everything possible to facilitate your best experience.",
    faq: [
      {
        question: "What is USDC?",
        answer:
          "USDC, a stablecoin, can be redeemed on a 1:1 basis for US dollars, as it is backed by assets denominated in dollars and securely held in segregated accounts within US regulated financial institutions. For VTRADER customers in jurisdictions where USDC support is available, exchanging 1 USDC for US$1.00 (and vice versa) is facilitated on the VTRADER platform.",
      },
      {
        question: "How do I buy USDC with a credit card?",
        answer:
          "You only need to create an account on VTRADER and pass an identity verification. Once completed, you will be able to top up your account using your credit card.",
      },
      {
        question: "How much USDC can I buy?",
        answer:
          "VTRADER is a flexible platform that lets you decide how much USDC you want to buy. Begin with $1 and go as high as you want, depending on your Tier (please consult our Terms & Conditions, or contact our Customer Support for more information).",
      },
      {
        question: "Is it safe to buy USDC on VTRADER?",
        answer:
          "Your security is our top priority. Upon purchasing any cryptocurrency, we perform identity verification for every user. This allows us to keep you and other visitors safe. In addition, we establish top-notch security measures to ensure your banking and personal USDC is protected.",
      },
      {
        question: "What can I do with USDC?",
        answer:
          "The possibilities are endless – whether you want to hold, sell, trade, or purchase using USDC. With VTRADER, you don’t have to worry about the safety of your coin. USDC will be safely stored on your account from the moment you make a purchase, and you will be able to access it at any time.",
      },
    ],
  },
  XLM: {
    coinName: "Stellar",
    pageTitle: "Buy Stellar with Credit or Debit Card | VTRADER",
    pageDescription: "Buy Stellar instantly.",
    whyBuy: `<p class='buy_heading'>We offer unmatched flexibility, security, and simplicity when it comes to purchasing Stellar. VTRADER is a premium platform to buy cryptocurrency at the best price, with the lowest fees.</p><p class='buy_heading'>VTRADER offers you many options to buy Stellar: <ul><li><img width='32' height='32' className="credit-card" alt="Credit card" src=${CreditCardLogo} />Buy Stellar with a credit card</li><li><img width='32' height='32' className="credit-card" alt="Credit card" src=${CreditCardLogo} />Buy Stellar with a debit card</li><li><img className="credit-card" width='32' height='32' alt="Credit card" src=${CurrencyHouse} />Buy Stellar with a bank transfer</li></ul></p>`,
    howToBuy:
      "<p class='buy_heading'>Check out our step-by-step guide of how to purchase Stellar for a smooth and secure transaction.</p><div class='buy_content'><div class='content_list'><span class='count'>1</span><h4>Register on VTRADER</h4><p>It takes under 5 minutes to create an account with us and start purchasing Stellar. (It’s 100% free, too!)</p></div><div class='content_list'><span class='count'>2</span><h4>Verify your identity</h4><p>Complete account verification by providing your identity and address documents to secure your transactions.</p></div><div class='content_list'><span class='count'>3</span><h4>Deposit USD/EUR/NGN</h4><p>Top up your VTRADER account by choosing any convenient fiat currency in Dollars, Euros, or Naira and get immediate access to the funds.</p></div><div class='content_list'><span class='count'>4</span><h4>Buy Stellar instantly</h4><p>Now you can buy Stellar online with a credit card, debit card, or Bank Wire. From $1 to infinity, invest as much as you so desire.</p></div></div>",
    credit:
      "Want to use Visa or Mastercard to make a purchase? Your wish is our command. Simply link your card details and buy Stellar with credit card instantly.",
    debit:
      "Buy Stellar with a debit card instantly using VTRADER. It’s user-friendly, quick, and secure – we have done everything possible to facilitate the best experience.",
    faq: [
      {
        question: "Why should I buy Stellar?",
        answer:
          "Stellar Lumen (XLM) fuels the Stellar payment network, a cross-border transfer system connecting financial entities for secure, near-instant transactions. Stellar aims to globally unify financial infrastructure, linking banks, payment systems, and individuals. Similar to the internet's role in connecting computers globally, Stellar seeks to do the same for money. Maintaining neutrality, Stellar operates as a decentralized, open network, supported by the nonprofit Stellar Development Foundation (SDF).",
      },
      {
        question: "How do I buy Stellar with a credit card?",
        answer:
          "You only need to create an account on VTRADER and pass an identity verification. Once completed, you will be able to top up your account using your credit card.",
      },
      {
        question: "Is it safe to buy Stellar on VTRADER?",
        answer:
          "Your security is our top priority. Upon purchasing any cryptocurrency, we perform identity verification for every user. This allows us to keep you and other visitors safe. In addition, we establish top-notch security measures to ensure your banking and personal data is protected.",
      },
      {
        question: "What can I do with Stellar?",
        answer:
          "The possibilities are endless – whether you want to hold, sell, trade, or purchase using XLM. With VTRADER, you don’t have to worry about the safety of your coin. Stellar will be safely stored on your account from the moment you make a purchase, and you will be able to access it at any time.",
      },
    ],
  },
};

export default currencyData;
