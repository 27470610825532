import React, { useEffect, useState } from "react";
import ContentLoader from "react-content-loader";

import "../dashboard/Dashboard.css";

import { AskIcon, BidIcon, UpsDownIcon, LastIcon } from "../dashboard/chart-icons";
import BittrexMarketSummaryService from "../../services/bittrex-market-summary/BittrexMarketSummaryService";
import { formatNumber } from "../../services/utils/formatNumber";
import { detectStringType } from "../../services/utils/detectStringType";
import BittrexMarketSummary from "../../services/api/common/response/BittrexMarketSummary";
import TradeViewChart from "../crypto-graph/TradeViewChart";
import { Fees } from "../../services/api/common/response/Fees";
import FeesTickersService from "../../services/api/common/fees-tickers-service/FeesTickersService";
import { getCurrencySymbol } from "../../services/utils/cryptoSymbols";
import { Link } from "react-router-dom";
import logoIcon from "../header/icons/lg.png";
import Select, { components } from "react-select";
import { useParams } from "react-router-dom";
import "./style.css";
import currencyData from "./currencyData";
import Faq from "./components/faq";
import CreditCardLogo from "./Credid_Card.svg";
import aaveIcon from "../dashboard/coin-icons/icon_aave.png";
import apeIcon from "../dashboard/coin-icons/icon_ape.png";
import adaIcon from "../dashboard/coin-icons/icon_ada.png";
import avaxIcon from "../dashboard/coin-icons/icon_avax.png";
import algoIcon from "../dashboard/coin-icons/icon_algo.png";
import atomIcon from "../dashboard/coin-icons/icon_atom.png";
import btcIcon from "../dashboard/coin-icons/icon_btc.png";
import daiIcon from "../dashboard/coin-icons/icon_dai.png";
import dotIcon from "../dashboard/coin-icons/icon_dot.png";
import compIcon from "../dashboard/coin-icons/icon_comp.png";
import dogeIcon from "../dashboard/coin-icons/icon_doge.png";
import ethIcon from "../dashboard/coin-icons/icon_eth.png";
import grtIcon from "../dashboard/coin-icons/icon_grt.png";
import linkIcon from "../dashboard/coin-icons/icon_link.png";
import ltcIcon from "../dashboard/coin-icons/icon_ltc.png";
import maticIcon from "../dashboard/coin-icons/icon_matic.png";
import mkrIcon from "../dashboard/coin-icons/icon_mkr.png";
import solIcon from "../dashboard/coin-icons/icon_sol.png";
import umaIcon from "../dashboard/coin-icons/icon_uma.png";
import uniIcon from "../dashboard/coin-icons/icon_uni.png";
import usdcIcon from "../dashboard/coin-icons/icon_usdc.png";
import usdtIcon from "../dashboard/coin-icons/icon_usdt.png";
import xlmIcon from "../dashboard/coin-icons/icon_xlm.png";
import xrpIcon from "../dashboard/coin-icons/icon_xrp.png";
import { Helmet } from "react-helmet";
import { AdaIcons } from "../icons-svg";

const Currency = () => {
  const { currencyName } = useParams<{ currencyName: string }>();

  let whyBuyTitle,
    whyBuy: any,
    howToBuyTitle,
    howToBuy: any,
    faq,
    pageDescription,
    pageTitle,
    coinName,
    debit,
    credit,
    fastBuyTitle,
    buyFastesWay: any,
    chooseToBuyTitle,
    chooseToBuy: any;

  if ((currencyData as any)[currencyName]) {
    ({
      whyBuyTitle,
      whyBuy,
      howToBuyTitle,
      howToBuy,
      faq,
      pageDescription,
      pageTitle,
      coinName,
      credit,
      debit,
      fastBuyTitle,
      buyFastesWay,
      chooseToBuyTitle,
      chooseToBuy,
    } = (currencyData as any)[currencyName]);
  }

  // Set default values here
  whyBuyTitle = whyBuyTitle || `Why buy ${coinName ? coinName : currencyName} with VTRADER?`;
  howToBuyTitle = howToBuyTitle || `How to buy ${coinName ? coinName : currencyName} on VTRADER`;

  const coinData = [
    { value: "USDT", label: "USDT", icon: usdtIcon },
    { value: "ETH", label: "ETH", icon: ethIcon },
    { value: "AAVE", label: "AAVE", icon: aaveIcon },
    { value: "ADA", label: "ADA", icon: adaIcon },
    { value: "APE", label: "APE", icon: apeIcon },
    { value: "AVAX", label: "AVAX", icon: avaxIcon },
    { value: "ALGO", label: "ALGO", icon: algoIcon },
    { value: "ATOM", label: "ATOM", icon: atomIcon },
    { value: "BTC", label: "BTC", icon: btcIcon },
    { value: "COMP", label: "COMP", icon: compIcon },
    { value: "DAI", label: "DAI", icon: daiIcon },
    { value: "DOGE", label: "DOGE", icon: dogeIcon },
    { value: "DOT", label: "DOT", icon: dotIcon },
    { value: "GRT", label: "GRT", icon: grtIcon },
    { value: "LINK", label: "LINK", icon: linkIcon },
    { value: "LTC", label: "LTC", icon: ltcIcon },
    { value: "MATIC", label: "MATIC", icon: maticIcon },
    { value: "MKR", label: "MKR", icon: mkrIcon, AdaIcons: AdaIcons },
    { value: "SOL", label: "SOL", icon: solIcon },
    { value: "UMA", label: "UMA", icon: umaIcon },
    { value: "UNI", label: "UNI", icon: uniIcon },
    { value: "USDC", label: "USDC", icon: usdcIcon },
    { value: "XLM", label: "XLM", icon: xlmIcon },
    { value: "XRP", label: "XRP", icon: xrpIcon },
  ];

  const { Option } = components;
  const IconOption = (props: any) => (
    <Option {...props}>
      <img src={props.data.icon} style={{ width: 12 }} alt={props.data.label} />
      {props.data.label}
    </Option>
  );

  const coinSelectedParam = currencyName;
  const [coinSelected, setCoinSelected] = useState("");
  const [coinSelectedBuy, setCoinSelectedBuy] = useState<any>(
    coinData.filter((coin) => coin.value === currencyName)[0]
  );

  const [detailedMarketSummaries, setDetailedMarketSummaries] =
    useState<Array<BittrexMarketSummary> | null>([]);
  const [coinBid, setCoinBid] = useState(0);
  const [coinAsk, setCoinAsk] = useState(0);
  const [coinLast, setCoinLast] = useState(0);
  const [coinChange1Hour, setCoinChange1Hour] = useState("");
  const [coinChange1Day, setCoinChange1Day] = useState("");
  const [coinChange7Days, setCoinChange7Days] = useState("");

  const [fees] = useState<Fees | null>(null);

  const [buyFee, setBuyFee] = useState(0);
  const [sellFee, setSellFee] = useState(0);

  const screenWidth = window.innerWidth;

  const feesTickerService: FeesTickersService = FeesTickersService.getInstance();
  const marketSummaryService = BittrexMarketSummaryService.getInstance();

  const [buyPrice, setBuyPrice] = useState("");
  const [buyAmount, setBuyAmount] = useState("");
  const [buyTotal, setBuyTotal] = useState("");
  const [canPlaceBuyOrder, setCanPlaceBuyOrder] = useState(false);

  const [buyOrderAmountValidation, setBuyOrderAmountValidation] = useState("");
  const [buyOrderTotalAmountValidation, setBuyOrderTotalAmountValidation] = useState("");

  const [sellPrice, setSellPrice] = useState("");
  const [sellAmount, setSellAmount] = useState("");
  const [sellTotal, setSellTotal] = useState("");

  const [usdtBalance] = useState(10000);

  useEffect(() => {
    setCoinSelected(coinSelectedParam);
  }, [currencyName]);

  useEffect(() => {
    const fetchMarketData = async () => {
      const summaries = marketSummaryService.getMarketDetailedSummaries();
      setDetailedMarketSummaries(summaries);
    };

    fetchMarketData().then(() => {});

    const intervalId = setInterval(fetchMarketData, 1000);

    resetForm();

    return () => {
      clearInterval(intervalId);
    };
  }, [coinSelected, buyFee, sellFee]);

  useEffect(() => {
    console.log(detailedMarketSummaries, "detailedMarketSummaries");
    const marketSummary = detailedMarketSummaries?.find(
      (summary) => summary.market === "USDT-" + coinSelected
    );
    if (marketSummary) {
      setCoinAsk(marketSummary.ask);
      setCoinBid(marketSummary.bid);
      setCoinLast(marketSummary.last);
      setCoinChange1Hour(
        marketSummary.percentChange1h >= 0
          ? "+" + formatNumber(marketSummary.percentChange1h).toString()
          : formatNumber(marketSummary.percentChange1h).toString()
      );
      setCoinChange1Day(
        marketSummary.percentChange24h >= 0
          ? "+" + formatNumber(marketSummary.percentChange24h).toString()
          : formatNumber(marketSummary.percentChange24h).toString()
      );
      setCoinChange7Days(
        marketSummary.percentChange7d >= 0
          ? "+" + formatNumber(marketSummary.percentChange7d).toString()
          : formatNumber(marketSummary.percentChange7d).toString()
      );
    }

    if (fees) {
      setBuyFee(fees.currencies[coinSelected].orderBuy);
      setSellFee(fees.currencies[coinSelected].orderSell);
    }
  }, [coinSelected, detailedMarketSummaries, fees]);

  const resetForm = () => {
    setBuyTotal("");
    setBuyPrice("");
    setBuyAmount("");
    setBuyOrderAmountValidation("");
    setBuyOrderTotalAmountValidation("");
    setCanPlaceBuyOrder(false);

    setSellTotal("");
    setSellPrice("");
    setSellAmount("");
  };

  const handleBuyPriceChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    setBuyPrice(event.target.value);
  };

  const handleBuyAmountChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    setBuyAmount(event.target.value);
  };

  const handleBuyTotalChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    setBuyTotal(event.target.value);
  };

  const calculateTotal = (price: number, amount: number) => {
    const total = Math.floor(price * amount * 1e6) / 1e6; // Round to 6 decimals
    return total.toString();
  };

  const calculateAmount = (price: number, total: number) => {
    const amount = Math.floor((total / price) * 1e6) / 1e6; // Round to 6 decimals
    return amount.toString();
  };

  const calculatePrice = (total: number, amount: number) => {
    const price = Math.floor((total / amount) * 1e6) / 1e6; // Round to 6 decimals
    return price.toString();
  };

  const getMinCryptoAmountToMeetMinTradeVolume = (): number => {
    const SATOSHI_MULTIPLIER: number = 100000000;
    const minTradeValueInSatoshi: number =
      feesTickerService.getMinTradeValueInSatoshi().minTradeValueInSatoshi + 1500;
    const minBtcAmount: number = minTradeValueInSatoshi / SATOSHI_MULTIPLIER;
    const oneBtcInUsdt: number =
      marketSummaryService.getMarketDetailedSummary("USDT-BTC")?.last || 0;

    const minAmountInUsdt = minBtcAmount * oneBtcInUsdt;
    const oneSelectedCoinInUsdt =
      marketSummaryService.getMarketDetailedSummary("USDT-" + coinSelected)?.last || 0;
    return Math.ceil((minAmountInUsdt / oneSelectedCoinInUsdt) * 1e6) / 1e6;
  };

  useEffect(() => {
    if (buyPrice !== "" && buyAmount !== "") {
      const newBuyTotal = calculateTotal(parseFloat(buyPrice), parseFloat(buyAmount));
      if (!isNaN(parseFloat(newBuyTotal))) {
        setBuyTotal(newBuyTotal);
      }
    } else if (buyPrice !== "" && buyTotal !== "") {
      const newBuyAmount = calculateAmount(parseFloat(buyPrice), parseFloat(buyTotal));
      if (!isNaN(parseFloat(newBuyAmount))) {
        setBuyAmount(newBuyAmount);
      }
    } else if (buyAmount !== "" && buyTotal !== "") {
      const newBuyPrice = calculatePrice(parseFloat(buyTotal), parseFloat(buyAmount));
      if (!isNaN(parseFloat(newBuyPrice))) {
        setBuyPrice(newBuyPrice);
      }
    }

    setBuyOrderAmountValidation("");
    setBuyOrderTotalAmountValidation("");

    setCanPlaceBuyOrder(
      parseFloat(buyPrice) > 0 && parseFloat(buyAmount) > 0 && parseFloat(buyTotal) > 0
    );
  }, [buyPrice, buyAmount, buyTotal]);

  useEffect(() => {
    if (sellPrice !== "" && sellAmount !== "") {
      const newSellTotal = calculateTotal(parseFloat(sellPrice), parseFloat(sellAmount));
      if (!isNaN(parseFloat(newSellTotal))) {
        setSellTotal(newSellTotal);
      }
    } else if (sellPrice !== "" && sellTotal !== "") {
      const newSellAmount = calculateAmount(parseFloat(sellPrice), parseFloat(sellTotal));
      if (!isNaN(parseFloat(newSellAmount))) {
        setSellAmount(newSellAmount);
      }
    } else if (sellAmount !== "" && sellTotal !== "") {
      const newSellPrice = calculatePrice(parseFloat(sellTotal), parseFloat(sellAmount));
      if (!isNaN(parseFloat(newSellPrice))) {
        setSellPrice(newSellPrice);
      }
    }
  }, [sellPrice, sellAmount, sellTotal]);

  const placeBuyOrder = async (event: React.MouseEvent<HTMLButtonElement>) => {
    window.open("https://app.vtrader.io/", "_blank");
  };

  const { selectedOption }: any = coinData[0].value;

  const [topBuyCurrencySelector, setTopBuyCurrencySelector] = useState<any>(coinData[0]);

  const skeletonTradeValues = () => {
    return (
      <ContentLoader
        speed={2}
        width={80}
        height={20}
        viewBox="0 0 80 20"
        backgroundColor="#c8e4db"
        foregroundColor="#e0f3ed"
      >
        <rect x="10" y="5" rx="3" ry="3" width="70" height="6" />
        {/* <rect x="10" y="10" rx="3" ry="3" width="50" height="6" /> */}
      </ContentLoader>
    );
  };

  const skeletonTradeValuesPercentage = () => {
    return (
      <ContentLoader
        speed={2}
        width={90}
        height={20}
        viewBox="0 0 90 20"
        backgroundColor="#c8e4db"
        foregroundColor="#e0f3ed"
      >
        <rect x="0" y="5" rx="3" ry="3" width="90" height="6" />
        {/* <rect x="0" y="10" rx="3" ry="3" width="70" height="6" /> */}
      </ContentLoader>
    );
  };

  return (
    <div>
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={pageDescription} />
      </Helmet>
      <div className="instant-buy-page">
        <div className="instant-page-container">
          <div className="heading-section">
            <div className="logo">
              <Link to={"/"}>
                <img className="background" alt="Background" src={logoIcon} />
              </Link>
            </div>
            <div className="instant-buy-heading-menu">
              {/* <div className="page-link-wrapper">
                                <Link to={'/about'} className="page-link-text">About</Link>
                            </div> */}
              <div className="page-link-wrapper link-btn">
                <Link to={"/Academy/academy"} className="page-link-btn">
                  Visit V-Trader Academy
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="instant-page-container">
          {/* <img className="ellipse" alt="Ellipse" src={background} /> */}
          <div className="form-logo-container">
            <div className="logo-title-wrapper">
              {/* { coinData.filter((coin) => coin.value === currencyName)[0].AdaIcons && (
                                <AdaIcons />
                            )} */}
              {/* <img
                                className="coin-img"
                                alt="Cryptocurrency maker"
                                src={coinData.filter((coin) => coin.value === currencyName)[0].icon}
                            /> */}
              <h1 className="coin-title">Buy {coinName ? coinName : currencyName}</h1>
              <p className="coin-subtitle">Instantly and Securely</p>
              <p className="coin-small-text">
                Low fees. Instant confirmation. Trustworthy service provider. VTRADER is trusted by
                millions.
              </p>
            </div>
            <div className="buy-form-wrapper">
              <form>
                <div className="buy_sell-form-input-wrapper">
                  <input
                    type="number"
                    title="Price (USDT)"
                    placeholder="Price (USDT)"
                    value={buyPrice}
                    onChange={handleBuyPriceChanged}
                  />
                  <div className="buy_sell-form-input-child-wrapper">
                    <div className="buy_sell-form-input-image">
                      <img src={topBuyCurrencySelector?.icon} alt="" />
                    </div>
                    <Select
                      className="single_select_form_input"
                      components={{ Option: IconOption }}
                      defaultValue={coinData[0]}
                      value={selectedOption}
                      onChange={(selectedOptionNew, selectedOption) => {
                        setTopBuyCurrencySelector(selectedOptionNew);
                      }}
                    />
                  </div>
                </div>
                <div className="buy_sell-form-input-wrapper">
                  <input
                    type="number"
                    title={`Amount (${coinSelected})`}
                    placeholder={`Amount (${coinSelectedBuy.value})`}
                    value={buyAmount}
                    onChange={handleBuyAmountChanged}
                  />
                  {buyOrderAmountValidation && buyAmount && (
                    <div className="order-validation-message">
                      {buyOrderAmountValidation}
                      <span
                        style={{
                          color: "black",
                          textDecoration: "underline",
                          cursor: "pointer",
                          fontSize: "10px",
                        }}
                        onClick={() => {
                          setBuyAmount(getMinCryptoAmountToMeetMinTradeVolume().toString());
                        }}
                      >
                        {getCurrencySymbol(coinSelected) +
                          getMinCryptoAmountToMeetMinTradeVolume().toString()}
                      </span>
                    </div>
                  )}
                  <div className="buy_sell-form-input-child-wrapper">
                    <div className="buy_sell-form-input-image">
                      <img
                        src={coinData.filter((coin) => coin.value === currencyName)[0]?.icon}
                        alt=""
                      />
                    </div>

                    <Select
                      options={coinData}
                      components={{ Option: IconOption }}
                      className="single_select_form_input"
                      defaultValue={coinData.filter((coin) => coin.value === currencyName)[0]}
                      value={coinData.filter((coin) => coin.value === currencyName)[0]}
                    />
                  </div>
                </div>
                <input
                  type="number"
                  className="buy_total_input"
                  title="Total (USDT)"
                  placeholder="Total (USDT)"
                  value={buyTotal}
                  onChange={handleBuyTotalChanged}
                />
                {buyOrderTotalAmountValidation && buyTotal && (
                  <div className="order-validation-message">
                    {buyOrderTotalAmountValidation} You have&nbsp;
                    <span
                      style={{
                        color: "black",
                        textDecoration: "underline",
                        cursor: "pointer",
                        fontSize: "10px",
                      }}
                      onClick={() => {
                        setBuyAmount("");
                        setBuyTotal(usdtBalance.toString());
                      }}
                    >
                      {getCurrencySymbol(coinSelected) + formatNumber(usdtBalance)}
                    </span>
                  </div>
                )}
                <button
                  className="trade-button"
                  style={{
                    backgroundColor: !canPlaceBuyOrder ? "#BBB" : "#111",
                    cursor: "pointer",
                  }}
                  onClick={placeBuyOrder}
                >
                  BUY
                </button>
              </form>
            </div>
          </div>
          <div className="subtext-wrapper">
            <p className="focusing-subtext">
              Enter the world of {currencyName} with VTRADER! Our all-in-one platform allows you to
              buy {coinName ? coinName : currencyName} <br />
              hassle-free, whether you’re a first-timer or a professional investor. You’re only a
              few clicks away!
            </p>
          </div>
        </div>
      </div>
      <div style={{ marginBottom: "50px" }}>
        {/* GARDIENT COLOR */}
        <div className="green-gardient-bg">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="1920"
            height="807"
            viewBox="0 0 1920 807"
            fill="none"
          >
            <g opacity="0.4" filter="url(#filter0_f_2_107)">
              <path
                d="M825.596 111.336C1468.78 -145.254 1963.8 54.1374 1966.13 247.46C1968.47 440.783 1823.12 319.684 1416.75 324.59C1010.37 329.497 -265.634 443.105 -267.968 249.782C-270.302 56.4595 -89.6682 363.628 825.596 111.336Z"
                fill="url(#paint0_radial_2_107)"
                fillOpacity="0.85"
              />
            </g>
            <defs>
              <filter
                id="filter0_f_2_107"
                x="-711.99"
                y="-454.412"
                width="3122.15"
                height="1260.6"
                filterUnits="userSpaceOnUse"
                colorInterpolationFilters="sRGB"
              >
                <feFlood floodOpacity="0" result="BackgroundImageFix" />
                <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                <feGaussianBlur stdDeviation="222" result="effect1_foregroundBlur_2_107" />
              </filter>
              <radialGradient
                id="paint0_radial_2_107"
                cx="0"
                cy="0"
                r="1"
                gradientUnits="userSpaceOnUse"
                gradientTransform="translate(954.624 288.229) rotate(12.9374) scale(878.598 379.12)"
              >
                <stop stopColor="#8ECCB1" />
                <stop stopColor="#8ECCB1" />
                <stop offset="0.370285" stopColor="#00A05A" />
                <stop offset="0.66901" stopColor="#9AE7FF" />
                <stop offset="0.86385" stopColor="#3C7B60" />
                <stop offset="1" stopColor="#A8F6DC" />
              </radialGradient>
            </defs>
          </svg>
        </div>
        <div className="trade-view-chart dashboard-container">
          {/* for USDT & UMA symbol/coins, the currency is different but Exchange is 'Bittrex' used. */}
          <TradeViewChart
            tradingPair={`${
              coinSelected === "USDT"
                ? "USDTUSD"
                : coinSelected === "UMA"
                ? "UMABTC"
                : coinSelected + "USDT"
            }`}
            width={screenWidth < 695 ? screenWidth - 30 : 570.892}
          />
          <div className="trade-ask-bid-container">
            <div className="trade-ask-bid-container-box">
              <AskIcon />
              <span className="trade-ask-bid-info">
                <p className="trade-ask-bid-container-box-title">Ask</p>
                <p className="trade-ask-bid-container-box-value">
                  ${coinAsk >= 0 ? formatNumber(coinAsk) : skeletonTradeValues()}
                </p>
              </span>
            </div>
            <div className="trade-ask-bid-container-box">
              <BidIcon />
              <span className="trade-ask-bid-info">
                <p className="trade-ask-bid-container-box-title">Bid</p>
                <p className="trade-ask-bid-container-box-value">
                  ${coinBid >= 0 ? formatNumber(coinBid) : skeletonTradeValues()}
                </p>
              </span>
            </div>
            <div className="trade-ask-bid-container-box">
              <LastIcon />
              <span className="trade-ask-bid-info">
                <p className="trade-ask-bid-container-box-title">Last</p>
                <p className="trade-ask-bid-container-box-value">
                  ${coinLast >= 0 ? formatNumber(coinLast) : skeletonTradeValues()}
                </p>
              </span>
            </div>
            <div className="trade-ask-bid-container-box">
              <UpsDownIcon />
              <span className="trade-ask-bid-info">
                <p className="trade-ask-bid-container-box-title">1H</p>
                <p
                  className={`trade-ask-bid-container-box-value ${
                    detectStringType(coinChange1Hour) ? "positive-value" : "negative-value"
                  }`}
                >
                  {coinChange1Hour ? coinChange1Hour + "%" : skeletonTradeValuesPercentage()}
                </p>
              </span>
            </div>
            <div className="trade-ask-bid-container-box">
              <UpsDownIcon />
              <span className="trade-ask-bid-info">
                <p className="trade-ask-bid-container-box-title">1D</p>
                <p
                  className={`trade-ask-bid-container-box-value ${
                    detectStringType(coinChange1Day) ? "positive-value" : "negative-value"
                  }`}
                >
                  {coinChange1Day ? coinChange1Day + "%" : skeletonTradeValuesPercentage()}
                </p>
              </span>
            </div>
            <div className="trade-ask-bid-container-box">
              <UpsDownIcon />
              <span className="trade-ask-bid-info">
                <p className="trade-ask-bid-container-box-title">7D</p>
                <p
                  className={`trade-ask-bid-container-box-value ${
                    detectStringType(coinChange7Days) ? "positive-value" : "negative-value"
                  }`}
                >
                  {coinChange7Days ? coinChange7Days + "%" : skeletonTradeValuesPercentage()}
                </p>
              </span>
            </div>
          </div>
        </div>
        {credit && debit ? (
          <div className="credit-debit-container dashboard-container">
            <div className="buy-with-cards-wrapper">
              <img
                width={55}
                height={55}
                className="credit-card"
                alt="Credit card"
                src={CreditCardLogo}
              />
              <p className="buy-with-card-title">
                Buy {coinName ? coinName : currencyName} with a credit card
              </p>
              <p className="buy-with-card-text">{credit}</p>
            </div>
            <div className="middle-border"> </div>
            <div className="buy-with-cards-wrapper">
              <img
                width={55}
                height={55}
                className="credit-card"
                alt="Credit card"
                src={CreditCardLogo}
              />
              <p className="buy-with-card-title">
                Buy {coinName ? coinName : currencyName} with a debit card
              </p>
              <p className="buy-with-card-text">{debit}</p>
            </div>
          </div>
        ) : (
          // full width for single card
          <>
            {credit || debit ? (
              <div className="credit-or-debit-container dashboard-container">
                <div className="buy-with-cards-wrapper">
                  <img className="credit-card" alt="Credit card" src={CreditCardLogo} />
                  <p className="buy-with-card-title">
                    Buy {coinName ? coinName : currencyName} with a {credit ? "credit" : "debit"}{" "}
                    card
                  </p>
                  <p
                    className="buy-with-card-text"
                    dangerouslySetInnerHTML={{ __html: credit || debit }}
                  ></p>
                </div>
              </div>
            ) : (
              ""
            )}
          </>
        )}
        {[fastBuyTitle, howToBuyTitle, whyBuyTitle, chooseToBuyTitle].map((item, index) => {
          switch (index) {
            case 0:
              return (
                item && (
                  <div className="instant-buy-content-wrapper dashboard-container" key={index}>
                    <p className="instant-content-title h4">{item}</p>
                    <div
                      className="instant-content-text"
                      dangerouslySetInnerHTML={{ __html: buyFastesWay }}
                    ></div>
                    <br></br>
                  </div>
                )
              );
            case 1:
              return (
                item && (
                  <div className="instant-buy-content">
                    <div className="instant-buy-content-wrapper dashboard-container" key={index}>
                      <p className="instant-content-title h4">{item}</p>
                      <div
                        className="instant-content-text"
                        dangerouslySetInnerHTML={{ __html: howToBuy }}
                      ></div>
                    </div>
                  </div>
                )
              );
            case 2:
              return (
                item && (
                  <div
                    className="instant-buy-content-wrapper why-buy dashboard-container"
                    key={index}
                  >
                    <p className="instant-content-title h4">{item}</p>
                    <div
                      className="instant-content-text"
                      dangerouslySetInnerHTML={{ __html: whyBuy }}
                    ></div>
                  </div>
                )
              );
            case 3:
              return (
                item && (
                  <div className="instant-buy-content-wrapper dashboard-container" key={index}>
                    <p className="instant-content-title h4">{item}</p>
                    <div
                      className="instant-content-text"
                      dangerouslySetInnerHTML={{ __html: chooseToBuy }}
                    ></div>
                  </div>
                )
              );
            default:
              return null; // or handle missing cases differently
          }
        })}

        <div className="instant-buy-content-wrapper dashboard-container">
          <p className="instant-content-title">FAQ</p>
          <div className="label">
            <p className="we-offer-unmatched">
              <span className="text-wrapper">
                {faq ? <Faq faqItems={faq} /> : <p>No FAQ available.</p>}
              </span>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Currency;
