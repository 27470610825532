import React from 'react'

export function AdaIcons() {
    return (
        <>
            <svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 50 50" fill="none">
                <g clipPath="url(#clip0_2_296)">
                    <path d="M6.57714 9.96551C7.12152 9.96551 7.56283 9.5242 7.56283 8.97982C7.56283 8.43545 7.12152 7.99414 6.57714 7.99414C6.03277 7.99414 5.59146 8.43545 5.59146 8.97982C5.59146 9.5242 6.03277 9.96551 6.57714 9.96551Z" fill="#0032AD" />
                    <path d="M11.14 9.96551C11.6844 9.96551 12.1257 9.5242 12.1257 8.97982C12.1257 8.43545 11.6844 7.99414 11.14 7.99414C10.5957 7.99414 10.1544 8.43545 10.1544 8.97982C10.1544 9.5242 10.5957 9.96551 11.14 9.96551Z" fill="#0032AD" />
                    <path d="M7.71725 11.9406C8.26163 11.9406 8.70293 11.4993 8.70293 10.9549C8.70293 10.4105 8.26163 9.96922 7.71725 9.96922C7.17287 9.96922 6.73157 10.4105 6.73157 10.9549C6.73157 11.4993 7.17287 11.9406 7.71725 11.9406Z" fill="#0032AD" />
                    <path d="M9.99987 7.99032C10.5443 7.99032 10.9856 7.54901 10.9856 7.00463C10.9856 6.46026 10.5443 6.01895 9.99987 6.01895C9.4555 6.01895 9.01419 6.46026 9.01419 7.00463C9.01419 7.54901 9.4555 7.99032 9.99987 7.99032Z" fill="#0032AD" />
                    <path d="M9.99987 11.9406C10.5443 11.9406 10.9856 11.4993 10.9856 10.9549C10.9856 10.4105 10.5443 9.96922 9.99987 9.96922C9.4555 9.96922 9.01419 10.4105 9.01419 10.9549C9.01419 11.4993 9.4555 11.9406 9.99987 11.9406Z" fill="#0032AD" />
                    <path d="M7.71725 7.99032C8.26163 7.99032 8.70293 7.54901 8.70293 7.00463C8.70293 6.46026 8.26163 6.01895 7.71725 6.01895C7.17287 6.01895 6.73157 6.46026 6.73157 7.00463C6.73157 7.54901 7.17287 7.99032 7.71725 7.99032Z" fill="#0032AD" />
                    <path d="M13.8973 9.51684C14.1827 9.51684 14.4141 9.28547 14.4141 9.00005C14.4141 8.71464 14.1827 8.48326 13.8973 8.48326C13.6119 8.48326 13.3805 8.71464 13.3805 9.00005C13.3805 9.28547 13.6119 9.51684 13.8973 9.51684Z" fill="#0032AD" />
                    <path d="M3.81928 9.51678C4.1047 9.51678 4.33607 9.28541 4.33607 8.99999C4.33607 8.71457 4.1047 8.4832 3.81928 8.4832C3.53387 8.4832 3.30249 8.71457 3.30249 8.99999C3.30249 9.28541 3.53387 9.51678 3.81928 9.51678Z" fill="#0032AD" />
                    <path d="M1.98592 9.32777C2.16692 9.32777 2.31364 9.18105 2.31364 9.00005C2.31364 8.81906 2.16692 8.67233 1.98592 8.67233C1.80493 8.67233 1.6582 8.81906 1.6582 9.00005C1.6582 9.18105 1.80493 9.32777 1.98592 9.32777Z" fill="#0032AD" />
                    <path d="M15.7306 9.32771C15.9116 9.32771 16.0584 9.18099 16.0584 8.99999C16.0584 8.819 15.9116 8.67227 15.7306 8.67227C15.5496 8.67227 15.4029 8.819 15.4029 8.99999C15.4029 9.18099 15.5496 9.32771 15.7306 9.32771Z" fill="#0032AD" />
                    <path d="M11.3783 5.15181C11.6637 5.15181 11.8951 4.92043 11.8951 4.63502C11.8951 4.3496 11.6637 4.11823 11.3783 4.11823C11.0929 4.11823 10.8615 4.3496 10.8615 4.63502C10.8615 4.92043 11.0929 5.15181 11.3783 5.15181Z" fill="#0032AD" />
                    <path d="M6.33845 13.8818C6.62386 13.8818 6.85524 13.6504 6.85524 13.365C6.85524 13.0796 6.62386 12.8482 6.33845 12.8482C6.05303 12.8482 5.82166 13.0796 5.82166 13.365C5.82166 13.6504 6.05303 13.8818 6.33845 13.8818Z" fill="#0032AD" />
                    <path d="M5.42257 15.2784C5.60356 15.2784 5.75029 15.1316 5.75029 14.9506C5.75029 14.7697 5.60356 14.6229 5.42257 14.6229C5.24157 14.6229 5.09485 14.7697 5.09485 14.9506C5.09485 15.1316 5.24157 15.2784 5.42257 15.2784Z" fill="#0032AD" />
                    <path d="M12.2942 3.377C12.4751 3.377 12.6219 3.23027 12.6219 3.04928C12.6219 2.86828 12.4751 2.72156 12.2942 2.72156C12.1132 2.72156 11.9664 2.86828 11.9664 3.04928C11.9664 3.23027 12.1132 3.377 12.2942 3.377Z" fill="#0032AD" />
                    <path d="M6.33845 5.15174C6.62386 5.15174 6.85524 4.92037 6.85524 4.63495C6.85524 4.34954 6.62386 4.11816 6.33845 4.11816C6.05303 4.11816 5.82166 4.34954 5.82166 4.63495C5.82166 4.92037 6.05303 5.15174 6.33845 5.15174Z" fill="#0032AD" />
                    <path d="M11.3783 13.8818C11.6637 13.8818 11.8951 13.6504 11.8951 13.365C11.8951 13.0795 11.6637 12.8482 11.3783 12.8482C11.0929 12.8482 10.8615 13.0795 10.8615 13.365C10.8615 13.6504 11.0929 13.8818 11.3783 13.8818Z" fill="#0032AD" />
                    <path d="M12.2942 15.2784C12.4751 15.2784 12.6219 15.1317 12.6219 14.9507C12.6219 14.7697 12.4751 14.623 12.2942 14.623C12.1132 14.623 11.9664 14.7697 11.9664 14.9507C11.9664 15.1317 12.1132 15.2784 12.2942 15.2784Z" fill="#0032AD" />
                    <path d="M5.42257 3.37706C5.60356 3.37706 5.75029 3.23033 5.75029 3.04934C5.75029 2.86834 5.60356 2.72162 5.42257 2.72162C5.24157 2.72162 5.09485 2.86834 5.09485 3.04934C5.09485 3.23033 5.24157 3.37706 5.42257 3.37706Z" fill="#0032AD" />
                    <path d="M8.85861 5.65094C9.18719 5.65094 9.45355 5.38457 9.45355 5.056C9.45355 4.72742 9.18719 4.46106 8.85861 4.46106C8.53004 4.46106 8.26367 4.72742 8.26367 5.056C8.26367 5.38457 8.53004 5.65094 8.85861 5.65094Z" fill="#0032AD" />
                    <path d="M8.85806 3.11115C9.08291 3.11115 9.26519 2.92887 9.26519 2.70402C9.26519 2.47917 9.08291 2.29689 8.85806 2.29689C8.63321 2.29689 8.45093 2.47917 8.45093 2.70402C8.45093 2.92887 8.63321 3.11115 8.85806 3.11115Z" fill="#0032AD" />
                    <path d="M8.85861 13.5389C9.18719 13.5389 9.45355 13.2725 9.45355 12.944C9.45355 12.6154 9.18719 12.349 8.85861 12.349C8.53004 12.349 8.26367 12.6154 8.26367 12.944C8.26367 13.2725 8.53004 13.5389 8.85861 13.5389Z" fill="#0032AD" />
                    <path d="M8.85806 15.7031C9.08291 15.7031 9.26519 15.5208 9.26519 15.296C9.26519 15.0711 9.08291 14.8889 8.85806 14.8889C8.63321 14.8889 8.45093 15.0711 8.45093 15.296C8.45093 15.5208 8.63321 15.7031 8.85806 15.7031Z" fill="#0032AD" />
                    <path d="M5.44369 7.62352C5.77227 7.62352 6.03863 7.35715 6.03863 7.02858C6.03863 6.7 5.77227 6.43364 5.44369 6.43364C5.11512 6.43364 4.84875 6.7 4.84875 7.02858C4.84875 7.35715 5.11512 7.62352 5.44369 7.62352Z" fill="#0032AD" />
                    <path d="M3.40655 6.2585C3.6314 6.2585 3.81368 6.07623 3.81368 5.85137C3.81368 5.62652 3.6314 5.44424 3.40655 5.44424C3.1817 5.44424 2.99942 5.62652 2.99942 5.85137C2.99942 6.07623 3.1817 6.2585 3.40655 6.2585Z" fill="#0032AD" />
                    <path d="M12.2738 11.5663C12.6024 11.5663 12.8688 11.2999 12.8688 10.9714C12.8688 10.6428 12.6024 10.3764 12.2738 10.3764C11.9453 10.3764 11.6789 10.6428 11.6789 10.9714C11.6789 11.2999 11.9453 11.5663 12.2738 11.5663Z" fill="#0032AD" />
                    <path d="M14.3114 12.5557C14.5362 12.5557 14.7185 12.3735 14.7185 12.1486C14.7185 11.9238 14.5362 11.7415 14.3114 11.7415C14.0865 11.7415 13.9042 11.9238 13.9042 12.1486C13.9042 12.3735 14.0865 12.5557 14.3114 12.5557Z" fill="#0032AD" />
                    <path d="M5.44369 11.5663C5.77227 11.5663 6.03863 11.2999 6.03863 10.9714C6.03863 10.6428 5.77227 10.3764 5.44369 10.3764C5.11512 10.3764 4.84875 10.6428 4.84875 10.9714C4.84875 11.2999 5.11512 11.5663 5.44369 11.5663Z" fill="#0032AD" />
                    <path d="M3.40655 12.5558C3.6314 12.5558 3.81368 12.3735 3.81368 12.1486C3.81368 11.9238 3.6314 11.7415 3.40655 11.7415C3.1817 11.7415 2.99942 11.9238 2.99942 12.1486C2.99942 12.3735 3.1817 12.5558 3.40655 12.5558Z" fill="#0032AD" />
                    <path d="M12.2738 7.62352C12.6024 7.62352 12.8688 7.35715 12.8688 7.02858C12.8688 6.7 12.6024 6.43364 12.2738 6.43364C11.9453 6.43364 11.6789 6.7 11.6789 7.02858C11.6789 7.35715 11.9453 7.62352 12.2738 7.62352Z" fill="#0032AD" />
                    <path d="M14.3114 6.25849C14.5362 6.25849 14.7185 6.07621 14.7185 5.85136C14.7185 5.62651 14.5362 5.44423 14.3114 5.44423C14.0865 5.44423 13.9042 5.62651 13.9042 5.85136C13.9042 6.07621 14.0865 6.25849 14.3114 6.25849Z" fill="#0032AD" />
                </g>
                <defs>
                    <clipPath id="clip0_2_296">
                        <rect width="100" height="100" fill="white"  />
                    </clipPath>
                </defs>
            </svg>
        </>
    )
}
