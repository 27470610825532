import React from "react";
import { Link } from "react-router-dom";
import "./Footer.css";
import ctaIcon from "./icons/cta_icon.png";
import appStoreIcon from "./icons/appstore.png";
import boxInnerIcon from "./icons/box-inner-icon.png";
import googlePlayIcon from "./icons/googleplay.png";
import currencyData from "../currency/currencyData";
import facebookIcon from "./icons/facebook.png";
import linkedinIcon from "./icons/linkedin.png";
import twitterIcon from "./icons/twitter.png";
import instagramIcon from "./icons/instagram.png";

const Footer = () => {
  return (
    <div>
      <div className="footer-sections">
        {/* SECTION - FOOTER CTA */}
        <div className="footer-cta-container">
          <div className="footer-inner-container">
            <div className="footer-cta-left-text">
              <img src={ctaIcon} alt="LEARN ABOUT CRYPTOCURRENCY" className="footer-cta-icon" />
              <span>LEARN ABOUT CRYPTOCURRENCY AND EARN USDT AT VTRADER ACADEMY</span>
            </div>
            <Link to="/academy" className="footer-cta-button">
              VTRADER ACADEMY
            </Link>
          </div>
        </div>

        {/* SECTION - FOOTER MENU */}
        <div className="footer-menu-container">
          <div className="footer-inner-container">
            <div className="footer-menu-left-side">
              <div>
                <span className="footer-left-side-text">
                  DOWNLOAD <span className="green-text">OUR TRADING APP</span>
                </span>
                <div className="footer-appstore-btn-container">
                  <Link
                    to={{
                      pathname:
                        "https://play.google.com/store/apps/datasafety?id=com.vtrader.droid",
                    }}
                    target="_blank"
                  >
                    <img src={googlePlayIcon} alt="google play store icon" className="big-img" />
                  </Link>
                  <Link
                    to={{
                      pathname:
                        "https://apps.apple.com/ro/app/vtrader-buy-bitcoin-doge/id1463403108",
                    }}
                    target="_blank"
                  >
                    <img src={appStoreIcon} alt="apple app store icon" className="big-img" />
                  </Link>
                </div>
              </div>
              <div>
                <span className="footer-left-side-text">
                  DOWNLOAD <span className="green-text">VTRADER’S eTH GAS TRACKER</span> TO GET
                  ALERTS TO YOUR PHONE ON ETH GAS PRICES-NEVER OVERPAY FOR TRANSACTIONS AGAIN{" "}
                </span>
                <div className="footer-appstore-btn-container">
                  <Link
                    to={{
                      pathname:
                        "https://play.google.com/store/apps/details?id=io.vtrader.ethereumstats&hl=en&gl=US",
                    }}
                    target="_blank"
                  >
                    <img src={googlePlayIcon} alt="google play store icon" />
                  </Link>
                  <Link
                    to={{
                      pathname:
                        "https://apps.apple.com/ro/app/ethereum-gas-tracker-alerts/id6449078810",
                    }}
                    target="_blank"
                  >
                    <img src={appStoreIcon} alt="apple app store icon" />
                  </Link>
                </div>
                <div className="footer-crypto-terms-box">
                  <img src={boxInnerIcon} alt="bitcoin icon" className="box-inner-icon" />
                  <span className="crypto-box-inner-text">
                    crypto glossary-
                    <Link
                      to={{ pathname: "https://vtrader.io/cryptoglossary" }}
                      target="_blank"
                      className="crypto-box-inner-link"
                    >
                      lookup any terms in crypto
                    </Link>
                  </span>
                </div>
              </div>
            </div>
            <div className="footer-menu-right-side">
              <span className="footer-menu-title">POPULAR CRYPTOS</span>
              <div className="footer-instant-buy-links">
                {(currencyData as any)["ADA"] ? (
                  <Link
                    to="/currencies/ada"
                    onClick={() => window.scrollTo(0, 0)}
                    className="instant-buy-link"
                  >
                    Instant Buy ADA
                  </Link>
                ) : (
                  ""
                )}
                {(currencyData as any)["UMA"] ? (
                  <Link
                    to="/currencies/uma"
                    onClick={() => window.scrollTo(0, 0)}
                    className="instant-buy-link"
                  >
                    Instant Buy UMA
                  </Link>
                ) : (
                  ""
                )}
                {(currencyData as any)["COMP"] ? (
                  <Link
                    to="/currencies/comp"
                    onClick={() => window.scrollTo(0, 0)}
                    className="instant-buy-link"
                  >
                    Instant Buy COMP
                  </Link>
                ) : (
                  ""
                )}
                {(currencyData as any)["DOGE"] ? (
                  <Link
                    to="/currencies/doge"
                    onClick={() => window.scrollTo(0, 0)}
                    className="instant-buy-link"
                  >
                    Instant Buy DOGE
                  </Link>
                ) : (
                  ""
                )}
                {(currencyData as any)["UNI"] ? (
                  <Link
                    to="/currencies/uni"
                    onClick={() => window.scrollTo(0, 0)}
                    className="instant-buy-link"
                  >
                    Instant Buy UNI
                  </Link>
                ) : (
                  ""
                )}
                {(currencyData as any)["MATIC"] ? (
                  <Link
                    to="/currencies/matic"
                    onClick={() => window.scrollTo(0, 0)}
                    className="instant-buy-link"
                  >
                    Instant Buy MATIC
                  </Link>
                ) : (
                  ""
                )}
                {(currencyData as any)["ATOM"] ? (
                  <Link
                    to="/currencies/atom"
                    onClick={() => window.scrollTo(0, 0)}
                    className="instant-buy-link"
                  >
                    Instant Buy ATOM
                  </Link>
                ) : (
                  ""
                )}
                {(currencyData as any)["APE"] ? (
                  <Link
                    to="/currencies/ape"
                    onClick={() => window.scrollTo(0, 0)}
                    className="instant-buy-link"
                  >
                    Instant Buy APE
                  </Link>
                ) : (
                  ""
                )}
                {(currencyData as any)["XLM"] ? (
                  <Link
                    to="/currencies/xlm"
                    onClick={() => window.scrollTo(0, 0)}
                    className="instant-buy-link"
                  >
                    Instant Buy XLM
                  </Link>
                ) : (
                  ""
                )}
                {(currencyData as any)["SOL"] ? (
                  <Link
                    to="/currencies/sol"
                    onClick={() => window.scrollTo(0, 0)}
                    className="instant-buy-link"
                  >
                    Instant Buy SOL
                  </Link>
                ) : (
                  ""
                )}
                {(currencyData as any)["LINK"] ? (
                  <Link
                    to="/currencies/link"
                    onClick={() => window.scrollTo(0, 0)}
                    className="instant-buy-link"
                  >
                    Instant Buy LINK
                  </Link>
                ) : (
                  ""
                )}
                {(currencyData as any)["DAI"] ? (
                  <Link
                    to="/currencies/dai"
                    onClick={() => window.scrollTo(0, 0)}
                    className="instant-buy-link"
                  >
                    Instant Buy DAI
                  </Link>
                ) : (
                  ""
                )}
                {(currencyData as any)["ALGO"] ? (
                  <Link
                    to="/currencies/algo"
                    onClick={() => window.scrollTo(0, 0)}
                    className="instant-buy-link"
                  >
                    Instant Buy ALGO
                  </Link>
                ) : (
                  ""
                )}
                {(currencyData as any)["USDT"] ? (
                  <Link
                    to="/currencies/usdt"
                    onClick={() => window.scrollTo(0, 0)}
                    className="instant-buy-link"
                  >
                    Instant Buy USDT
                  </Link>
                ) : (
                  ""
                )}
                {(currencyData as any)["MKR"] ? (
                  <Link
                    to="/currencies/mkr"
                    onClick={() => window.scrollTo(0, 0)}
                    className="instant-buy-link"
                  >
                    Instant Buy MKR
                  </Link>
                ) : (
                  ""
                )}
                {(currencyData as any)["LTC"] ? (
                  <Link
                    to="/currencies/ltc"
                    onClick={() => window.scrollTo(0, 0)}
                    className="instant-buy-link"
                  >
                    Instant Buy LTC
                  </Link>
                ) : (
                  ""
                )}
                {(currencyData as any)["BTC"] ? (
                  <Link
                    to="/currencies/btc"
                    onClick={() => window.scrollTo(0, 0)}
                    className="instant-buy-link"
                  >
                    Instant Buy BTC
                  </Link>
                ) : (
                  ""
                )}
                {(currencyData as any)["XRP"] ? (
                  <Link
                    to="/currencies/xrp"
                    onClick={() => window.scrollTo(0, 0)}
                    className="instant-buy-link"
                  >
                    Instant Buy XRP
                  </Link>
                ) : (
                  ""
                )}
                {(currencyData as any)["AVAX"] ? (
                  <Link
                    to="/currencies/avax"
                    onClick={() => window.scrollTo(0, 0)}
                    className="instant-buy-link"
                  >
                    Instant Buy AVAX
                  </Link>
                ) : (
                  ""
                )}
                {(currencyData as any)["GRT"] ? (
                  <Link
                    to="/currencies/grt"
                    onClick={() => window.scrollTo(0, 0)}
                    className="instant-buy-link"
                  >
                    Instant Buy GRT
                  </Link>
                ) : (
                  ""
                )}
                {(currencyData as any)["DOT"] ? (
                  <Link
                    to="/currencies/dot"
                    onClick={() => window.scrollTo(0, 0)}
                    className="instant-buy-link"
                  >
                    Instant Buy DOT
                  </Link>
                ) : (
                  ""
                )}
                {(currencyData as any)["ETH"] ? (
                  <Link
                    to="/currencies/eth"
                    onClick={() => window.scrollTo(0, 0)}
                    className="instant-buy-link"
                  >
                    Instant Buy ETH
                  </Link>
                ) : (
                  ""
                )}
                {(currencyData as any)["USDC"] ? (
                  <Link
                    to="/currencies/usdc"
                    onClick={() => window.scrollTo(0, 0)}
                    className="instant-buy-link"
                  >
                    Instant Buy USDC
                  </Link>
                ) : (
                  ""
                )}
                {(currencyData as any)["XBT"] ? (
                  <Link
                    to="/currencies/xbt"
                    onClick={() => window.scrollTo(0, 0)}
                    className="instant-buy-link"
                  >
                    Instant Buy XBT
                  </Link>
                ) : (
                  ""
                )}
                {(currencyData as any)["AAVE"] ? (
                  <Link
                    to="/currencies/aave"
                    onClick={() => window.scrollTo(0, 0)}
                    className="instant-buy-link"
                  >
                    Instant Buy AAVE
                  </Link>
                ) : (
                  ""
                )}
              </div>
              <div className="footer-social">
                <span className="footer-menu-title">FOLLOW US</span>
                <div>
                  <a href="https://twitter.com/vTrader_io">
                    <img src={twitterIcon} alt="x" />
                  </a>
                  <a href="https://twitter.com/vTraderAfrica">
                    <img src={twitterIcon} alt="x" className="right-panel-sm-icon" />
                  </a>
                  <a href="https://www.linkedin.com/company/13012475/">
                    <img src={linkedinIcon} alt="linkedin" className="right-panel-sm-icon" />
                  </a>
                  <a href="https://www.facebook.com/vtrader.io/">
                    <img src={facebookIcon} alt="fecebook" className="right-panel-sm-icon" />
                  </a>
                  <a href="https://www.instagram.com/vtraderafrica/">
                    <img src={instagramIcon} alt="instagram" className="right-panel-sm-icon" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* SECTION - FOOTER COPYRIGHT */}
        <div className="footer-copyright-section">
          <div className="footer-inner-container copyright">
            <div className="rectangle"></div>
            <div className="copyright-text-container">
              <div className="copyright-text-left">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="13"
                  height="13"
                  viewBox="0 0 13 13"
                  fill="none"
                >
                  <path
                    d="M5.67384 0.0341549C4.17517 0.229743 2.83399 0.913033 1.78239 2.0129C0.870487 2.96798 0.268481 4.18216 0.0601915 5.48269C-0.00585143 5.88911 -0.0210921 6.85182 0.0322503 7.28617C0.278641 9.31826 1.45979 11.0989 3.24803 12.1454C3.8983 12.5239 4.64255 12.7906 5.44269 12.9303C5.88467 13.0065 6.82197 13.0243 7.28935 12.9633C10.0886 12.6128 12.3289 10.5375 12.8878 7.7815C13.0071 7.19727 13.0376 6.29807 12.964 5.6859C12.5982 2.74699 10.2511 0.399931 7.31221 0.0341549C6.92612 -0.0115671 6.03962 -0.0115671 5.67384 0.0341549ZM7.23601 0.874931C9.2808 1.13148 11.0894 2.56919 11.8158 4.51745C12.2095 5.5716 12.2756 6.79847 11.9987 7.88564C11.4907 9.87201 9.95647 11.4316 7.97772 11.9752C6.85245 12.2826 5.57224 12.2191 4.47745 11.8C2.78827 11.1522 1.48265 9.69928 1.01273 7.94152C0.771423 7.0347 0.771423 5.96278 1.01273 5.05596C1.27945 4.06023 1.81541 3.14579 2.55204 2.43456C3.81448 1.21277 5.49095 0.656482 7.23601 0.874931Z"
                    fill="black"
                  />
                  <path
                    d="M5.90488 3.28841C5.49846 3.36208 5.03616 3.55258 4.65769 3.80151C4.42654 3.95392 3.95154 4.43146 3.80167 4.65753C3.41811 5.23922 3.24792 5.81074 3.24792 6.49911C3.24792 7.19002 3.42065 7.76155 3.80167 8.3407C3.95408 8.56931 4.42908 9.04431 4.65769 9.19671C5.04632 9.45327 5.501 9.63615 5.9252 9.71236C6.21478 9.76062 6.78122 9.76316 7.06825 9.71236C7.68042 9.60567 8.33577 9.28054 8.71679 8.8919L8.83617 8.76997L8.54406 8.4804C8.32307 8.25941 8.24433 8.19591 8.21638 8.21369C8.1986 8.22893 8.10462 8.30513 8.01064 8.38388C7.5966 8.73187 7.07079 8.91476 6.48657 8.91476C5.42226 8.91476 4.47988 8.20353 4.17507 7.17224C4.09124 6.89283 4.06076 6.40259 4.11156 6.10286C4.26143 5.16301 4.98028 4.39082 5.92774 4.14951C6.63897 3.96662 7.45943 4.14951 8.01064 4.61435C8.10462 4.69309 8.1986 4.7693 8.21638 4.78454C8.24433 4.80232 8.32307 4.73882 8.54406 4.51783L8.83617 4.22825L8.71679 4.10633C8.33577 3.71769 7.68042 3.39256 7.06825 3.28587C6.79138 3.23761 6.17667 3.24015 5.90488 3.28841Z"
                    fill="black"
                  />
                </svg>
                <span>Copyright vTrader 2024</span>
              </div>
              <div className="copyright-text-right">
                <Link
                  to={{ pathname: "https://app.vtrader.io/privacy-policy" }}
                  target="_blank"
                  className="copyright-text-link"
                >
                  Privacy Policy
                </Link>
                <Link
                  to={{ pathname: "https://app.vtrader.io/terms-and-conditions" }}
                  target="_blank"
                  className="copyright-text-link"
                >
                  Terms & Conditions
                </Link>
                <Link
                  to={{ pathname: "https://app.vtrader.io/about" }}
                  target="_blank"
                  className="copyright-text-link"
                >
                  About Us
                </Link>
              </div>
            </div>
            <div className="rectangle"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
