// @ts-nocheck

import React from "react";
import TradingViewWidget from "react-tradingview-widget";

const ChartComponent = ({ tradingPair, exchange = "binance", height = 400, width = 695 }) => {
  return (
    <div className="trade-view-widget">
      <TradingViewWidget
        symbol={`${exchange.toUpperCase()}:${tradingPair}`} // Use the provided trading pair and exchange
        interval="D" // Set the chart interval (D = Daily)
        theme="Light" // Set the chart theme (dark or light)
        style="1" // Set the chart style (1, 2, 3, etc.)
        locale="en" // Set the chart locale (en, es, etc.)
        autosize
      />
    </div>
  );
};

export default ChartComponent;
